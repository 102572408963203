/**
 * Created by NgiTeam on 14/03/2017.
 * Project : TC-Fleet
 */

angular.module("app.directives").directive("infos", function ($rootScope, $state, leafletData, geoServiceProxy, $translate,dUtilsSrv) {

    return {
        scope: {
            map: '@'
        },
        link: function (scope) {
            scope.currentPosition = {};
            var infolayer = new L.layerGroup();
            function infos(e) {
                infolayer.clearLayers();
                var popLocation = new L.LatLng(e.latlng.lat, e.latlng.lng);
                scope.currentPosition.latlng = popLocation;
                geoServiceProxy.findPointOfInterests(1, e.latlng.lng, e.latlng.lat, 100, "xx", "", "", "").then(function (res) {
                    if (res.BND.GeocodingElements.GeocodingElement.length > 0) {
                        var elem = res.BND.GeocodingElements.GeocodingElement[0];
                        var address = dUtilsSrv.formatAddress(elem);
                        if (address)
                            address = '<i class="fa fa-globe"></i> ' + address;
                        else
                            address = '<i class="fa fa-globe"></i> ' +$translate.instant("UNDEFINED_ADDRESS");
                        if (res.BND.FeatureElements.FeatureElement.length > 0) {
                            var poiList =[];
                            res.BND.FeatureElements.FeatureElement.forEach(function (value) {
                             if (value.ClassId && value.ClassId > 7000){
                                 poiList.push(value);
                             }
                             })
                        var poi ="";
                            poi =getNearPoi(poiList,popLocation);
                            if (address )
                                address += '<br>';
                            if(poi)
                            address += '<i class="fa fa-map-marker"></i> ' + poi;
                        }
                        message = '<div class="map-popup"><div class="popup-heading">' + address + '</div><div class="popup-details">' + $translate.instant("LONGITUDE")+" : " + e.latlng.lng + '<br> '+$translate.instant("LATITUDE")+' : ' + e.latlng.lat + '</div>' +
                            '<div align="right"><img src="./app/commons/directives/map/images/start_flag_16.png" style="cursor: pointer;padding-right: 1em;" onClick="startStep()" title="'+$translate.instant("STARTING_POINT")+'">' +
                            '<img src="./app/commons/directives/map/images/finish_flag_16.png" style="cursor: pointer;" onClick="finishStep()" title="'+$translate.instant("ARRIVAL_POINT")+'"></div></div>';
                        setTimeout(function () {
                            L.marker([e.latlng.lat, e.latlng.lng]).addTo(infolayer)
                                .bindPopup(message)
                                .openPopup();

                            scope.$apply();
                        }, 0);

                    }
                    else {

                    }
                }, function (error) {
                })

            };
            scope.$on('infos', function (event, e) {
                infos(e);
            });
            leafletData.getMap(""+scope.map).then(function (map) {
                infolayer.addTo(map);
                map.on('popupclose', function () {
                    infolayer.clearLayers();

                })
            });
            startStep = function(){
                addStartStep(scope.currentPosition);
            };
            finishStep = function(){
                addFinishStep(scope.currentPosition);
            };
            function addStartStep(e) {
                if($state.current.name.startsWith('app.realtime')){
                    $state.go("app.realtime.routing")
                }
                else if ($state.current.name.startsWith('app.historic')){
                    $state.go("app.historic.routing")
                }
                setTimeout(function(){
                    $rootScope.$broadcast('addStartStep', e);
                },1000);
            };
            function addFinishStep(e) {
                if($state.current.name.startsWith('app.realtime')){
                    $state.go("app.realtime.routing")
                }
                else if ($state.current.name.startsWith('app.historic')){
                    $state.go("app.historic.routing")
                }
                setTimeout(function(){
                    $rootScope.$broadcast('addFinishStep', e);
                },1000);
            };


            function getNearPoi(poiList,popLocation){
                var distance;
                var POI="";
                for (var i = 0; i < poiList.length; i++) {
                        var lat2 = poiList[i].Coordinate.y;
                        var lon2 = poiList[i].Coordinate.x;
                        var R = 6371; // Radius of the earth in km
                        var dLat = (lat2 - popLocation.lat) * (Math.PI / 180);  // deg2rad below
                        var dLon = (lon2 - popLocation.lng) * (Math.PI / 180);
                        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(popLocation.lat * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
                        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                        var d = R * c * 1000; // Distance in m
                    if (!distance || d < distance) {
                            distance = d;
                            POI = "" + poiList[i].Attributes[0].Attribute.value;
                        }



                }
                return POI;
            }


         }
    }
});