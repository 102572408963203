/**
 * Created by ngi02 on 28/02/2018.
 */
angular.module('app').controller('degSitesCtrl', function ($rootScope,$stateParams, $timeout, $scope,stationSrv,deviceMsgStationSrv,$translate,$q,$state, ngDialog) {


$scope.stationsList=[];


var getStationList=function(){
   var query= [
        {
            "$match": {
               // "station1_id": $scope.station.id,
              //  "time_stamp": {$gte: $scope.startRes ,
             //      $lte: $scope.endRes
            //  }

            }
        },  {
           $lookup: {
               from: "station",
               localField: "station1_id",
               foreignField: "id",
               as: "station"
           }
       }, {
            "$project": {

                "bs": {$arrayElemAt: ["$station", 0]},
                "st1": "$station1_id",
                "rep4": "$repetitions4",
                "rep3": "$repetitions3",
                "rep2": "$repetitions2",
                "dt": {"$add": ["$time_stamp", moment().utcOffset() * 60 * 1000]},
                "ack":"$ack",
                "true":{$cond: { if: { $eq: [ "$ack", true ] }, then: 1, else: 0 }},
                "false":{$cond: { if: { $eq: [ "$ack", false ] }, then: 1, else: 0 }},
                "st4rep":{$cond: { if: { $eq: [ "$repetitions4", null ] }, then: 0, else: 1 }},
                "st3rep":{$cond: { if: { $eq: [ "$repetitions3", null ] }, then: 0, else: 1 }},
                "st2rep":{$cond: { if: { $eq: [ "$repetitions2", null ] }, then: 0, else: 1 }}

             }
        },
        {
            "$group": {
                "_id": "$st1",

                "ID":{"$last": "$bs._id"},
                "last_ping" : {"$last": "$bs.last_ping_date"},
                "down_link" : {"$last": "$bs.down_link_enbl"},
                "loss" : {"$last": "$bs.cable_loss"},
                "last_dt": {"$last": "$dt"},
                "up_msg": {"$sum": "$false"},
                "dw_msg": {"$sum": "$true"},
                "msg_rep4": {"$sum":"$st4rep"},
                "msg_rep3": {"$sum":"$st3rep"},
                "msg_rep2": {"$sum":"$st2rep"},

                "count": {"$sum": 1}
                      }
        },
        {"$sort": {"_id": -1}}

    ];


    deviceMsgStationSrv.getDeviceMsgStationAgg2({aggregate:query},1000000,0, function (res) {
        console.log('res agg',res);
var data=[];
        if (res && res.result && res.result.length > 0) {

            res.result.forEach(function (val) {

                    var elemTab = {};

                if(val._id)
                elemTab.id=val._id;

                if(val.ID)
                elemTab._id=val.ID;

                if(val.count)
                elemTab.count=val.count;

                if(val.down_link)
                elemTab.down_link=val.down_link;

                if(val.dw_msg)
                elemTab.dw_msg=val.dw_msg;

                if(val.up_msg)
                elemTab.up_msg=val.up_msg;

                if(val.last_dt)
                elemTab.last_dt=val.last_dt;

                if(val.last_ping)
                elemTab.last_ping=val.last_ping;

                if(val.msg_rep2 && val.msg_rep3 && val.msg_rep4 && val.count)
                elemTab.msg_repAvg=((val.msg_rep2 + val.msg_rep3 + val.msg_rep4)/(val.count>0 ? val.count:1));

                data.push(elemTab)

                    });


            $timeout(function(){
                data.sort(function(a,b){ return    b.msg_repAvg - a.msg_repAvg} );
            });

            data.slice(0,49)

            $scope.stationsList=data;
            console.log('$scope.histoData',data)

        }else{


        }


    }, function (err) {

        if (err && err.status === -1) {
            Notification.error("Probl�me de connexion Internet");
        }
        console.error("Device message error ", err);
    }, true)






};

    $scope.goToBS=function(id) {

console.log('id',id)
        if (id) {

            if ($state.current.name === "app.home.degSites")
                $state.go('app.home.statics',{statId: id});



        }
    };




    getStationList();
});