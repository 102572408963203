
angular.module("app.services").factory('deviceMsgUtils',[function () {



    var service={};

    service.getDailyQuery=function(){
        return {


            "project": {

                "y": {"$year":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                "m": {"$month":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                "d": {"$dayOfMonth":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}}

            },
            "group": {
                "y": "$date.y",
                "m": "$date.m",
                "d": "$date.d"
            }



        }
    };
    service.getHourlyQuery=function(NbHours){
        if(!NbHours || NbHours===1) {
            return {

                "project": {
                    "y": {"$year":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "m": {"$month":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "d": {"$dayOfMonth":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "h": {"$hour":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}}

                },
                "group": {
                    "y": "$date.y",
                    "m": "$date.m",
                    "d": "$date.d",
                    "h": "$date.h"
                }}


        }else{
            return {
                "project": {
                    "y": {"$year":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "m": {"$month":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "d": {"$dayOfMonth":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "h": {"$floor": {$divide:[{"$hour":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}} ,NbHours]}}

                },
                "group": {
                    "y": "$date.y",
                    "m": "$date.m",
                    "d": "$date.d",
                    "h": "$date.h"
                }}
        }
    };
    service.getMinuteQuery=function(nbMinute){
        // console.log("getMinuteQuery",nbMinute,startDate.toDate().toUTCString(),endDate.toDate().toUTCString())

        if(!nbMinute || nbMinute===1)

        {
            return {

                "project": {
                    "y": {"$year":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "m": {"$month":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "d": {"$dayOfMonth":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "h": {"$hour":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "mm": {"$minute":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}}

                },
                "group": {
                    "y": "$date.y",
                    "m": "$date.m",
                    "d": "$date.d",
                    "h": "$date.h",
                    "mm": "$date.mm"
                }}


        }else{
            return {
                "project": {
                    "y": {"$year":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "m": {"$month":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "d": {"$dayOfMonth":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                    "h": {"$hour":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}}  ,
                    "mm": {"$floor": {$divide:[{"$minute":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},nbMinute]}}

                },
                "group": {
                    "y": "$date.y",
                    "m": "$date.m",
                    "d": "$date.d",
                    "h": "$date.h",
                    "mm": "$date.mm"
                }}
        }

    };
    service.getSecondsQuery=function(){

        return {

            "project": {
                "y": {"$year":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                "m": {"$month":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                "d": {"$dayOfMonth":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                "h": {"$hour":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}}  ,
                "mm":  {"$minute":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}},
                "ss":{"$second":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}}   ,
                "ms": {"$millisecond":{"$add":["$time_stamp",moment().utcOffset()*60*1000]}}

            },
            "group": {
                "y": "$date.y",
                "m": "$date.m",
                "d": "$date.d",
                "h": "$date.h",
                "mm": "$date.mm",
                "ss": "$date.ss",
                "ms": "$date.ms"
            }}


    };
    service.BuildQueryByPeriod=function(startDate,endDate){
        var startDate=moment(startDate);
        var endDate=moment(endDate);


        var days=endDate.diff(startDate, 'days');
        var hours =endDate.diff(startDate, 'hours');
        var minute =endDate.diff(startDate, 'minutes');
        var seconds =endDate.diff(startDate, 'seconds');

        return this.getSecondsQuery();
    };
    return service;
}]);
