/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */

angular.module("app.services").factory('loginSrv', function ($http, $rootScope, $window, jwtHelper, dataSrv, socket) {


    var service = {};
    var loggedIn = false;

    var connectionNotificationListner = function () {
    socket.connect();





    };

    service.login = function (user, success, error) {


        $http.post($rootScope.config.API_URI + '/login', user).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available

            //console.log("LOGIN ");
            if (response &&  response.data &&  response.data.success) {
                //console.log("LOGIN OK");
                $window.sessionStorage.token = response.data.result.token;


                $rootScope.SESSION = response.data.result;
                $window.sessionStorage.SESSION= JSON.stringify(response.data.result);
                 loggedIn = true;
              //connectionNotificationListner()

                success(response.data);
            }
            else {

                delete $window.sessionStorage.token;
                delete  $window.sessionStorage.SESSION;
              loggedIn = false;

                error(response.data);
            }


        },function (data) {
            error(data);
            delete $window.sessionStorage.token;


            loggedIn = false;
        });
    };
    /**
     * Used for thirdParty authorisation  facebook

     * @param tokenData token data got after authentication over oauth
     * @param success success calback funtion for   authentication
     * @param error success calback funtion for   authentication
     */
    service.facebookLogin = function (tokenData, success, error) {


        $http.post($rootScope.config.API_URI + '/auth/facebook', tokenData).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available

            //console.log("LOGIN ");
            if (response &&  response.data &&  response.data.success) {
                //console.log("LOGIN OK");
                $window.sessionStorage.token = response.data.result.token;


                $rootScope.SESSION = response.data.result;
                $window.sessionStorage.SESSION= JSON.stringify(response.data.result);
                loggedIn = true;
                //connectionNotificationListner()

                success(response.data);
            }
            else {

                delete $window.sessionStorage.token;
                delete  $window.sessionStorage.SESSION;
                loggedIn = false;

                error(response.data);
            }


        },function (data) {
            error(data);
            delete $window.sessionStorage.token;


            loggedIn = false;
        });
    };

    /**
     * Used for thirdParty authorisation  google

     * @param tokenData token data got after authentication over oauth
     * @param success success calback funtion for   authentication
     * @param error success calback funtion for   authentication
     */
    service.googleLogin = function (tokenData, success, error) {


        $http.post($rootScope.config.API_URI + '/auth/google', tokenData).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available

            //console.log("LOGIN ");
            if (response &&  response.data &&  response.data.success) {
                //console.log("LOGIN OK");
                $window.sessionStorage.token = response.data.result.token;


                $rootScope.SESSION = response.data.result;
                $window.sessionStorage.SESSION= JSON.stringify(response.data.result);
                loggedIn = true;
                //connectionNotificationListner()

                success(response.data);
            }
            else {

                delete $window.sessionStorage.token;
                delete  $window.sessionStorage.SESSION;
                loggedIn = false;

                error(response.data);
            }


        },function (data) {
            error(data);
            delete $window.sessionStorage.token;


            loggedIn = false;
        });
    };
    service.logout = function (success) {
        delete $window.sessionStorage.token;
        delete $window.sessionStorage.SESSION;
        loggedIn = false;

        //console.log(socket);
    /*    socket.closeListner("newMsg",function(){
            console.log('close notification newMSg');
        });*/


        if (success) success()
    };


    service.setLoggedInStatus = function (logged) {
        loggedIn = logged;


    };
    service.isLoggedIn = function () {
        return loggedIn;
    };

    /*
     Reset password
     */
    service.forgetPassword = function (userData, success, error) {
        $http.post($rootScope.config.API_URI + '/forget', userData).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success) {
                success(response.data);
            }else if (response && response.data && response.data.error) {
                success(response.data);
            }

        }),function (data) {
            error(data)
        };
    };

    service.register = function (user, company, accessToken, authenticationType, success, error) {

        $http.post($rootScope.config.API_URI + '/register', {user: user, company: company, authentificationType: authenticationType, accessToken: accessToken}).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available

            //console.log("LOGIN ");
            if (response &&  response.data &&  response.data.success) {
                //console.log("LOGIN OK");
                $window.sessionStorage.token = response.data.result.token;


                $rootScope.SESSION = response.data.result;
                $window.sessionStorage.SESSION= JSON.stringify(response.data.result);
                loggedIn = true;
                //connectionNotificationListner()

                success(response.data);
            }
            else {

                delete $window.sessionStorage.token;
                delete  $window.sessionStorage.SESSION;
                loggedIn = false;

                error(response.data);
            }


        },function (data) {
            error(data);
            delete $window.sessionStorage.token;


            loggedIn = false;
        });
    };

    service.facebookRegister = function (tokenData, success, error) {

        $http.get('https://graph.facebook.com/me?&fields=email,name,id,gender,first_name,last_name&access_token='+tokenData.access_token,tokenData).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response &&  response.data) {
                success(response.data);
            }
            else {
                error(response.data);
            }

        },function (data) {
            error(data);
        });
    };

    service.googleRegister = function (tokenData, success, error) {

        $http.get('https://www.googleapis.com/oauth2/v1/userinfo?access_token='+tokenData.access_token, tokenData).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response &&  response.data) {
                success(response.data);
            }
            else {

                error(response.data);
            }

        },function (data) {
            error(data);
        });
    };


    return service;

});
