/**
 * Project: fleetWeb
 * Created by Haythem Horbit on 29/09/15.
 */


angular.module("app.services").factory('socket', function ($rootScope,$timeout,CONFIG,$window,$state) {
  var service = {};
  service.isConnected = false;
  var eventListener={};
  service.connect = function (clb) {
     if(!$window.sessionStorage.SESSION || !$window.sessionStorage.token ){
       console.error('Cannot connect  to RealTime notification cause session invalid')
      if(!clb) clb=function(){};
       return  clb("RT_SESSION_NOT_FOUND");
     }
    if (typeof io === "undefined") {
      service.on = function (eventName, callback) {
        console.error("Can't listnen to event " + eventName + ', No RT Server');
      };
      service.emit = function (eventName, callback) {
        console.error("Can't emit event " + eventName + ', No RT Server');
      };
      service.isConnected = false;


      if(clb) clb(false)
    } else {
      ///console.log($rootScope.RT_CONNECTED &&  $rootScope.isConnected);
      if( $rootScope.RT_CONNECTED &&  $rootScope.isConnected){
        console.log("already connected");
        return ;
      }
      var socket = io.connect(CONFIG.EVENT_URI, {
        'query': 'token=' + $window.sessionStorage.token})

      service.on = function (eventName, callback) {
        eventListener[eventName+'_'+callback.name]=function () {

          var args = arguments;
          $rootScope.$emit(eventName,args);
          $rootScope.$apply(function () {

            callback.apply(socket, args);
          });
        };

        socket.on(eventName, eventListener[eventName+'_'+callback.name]);
        eventListener[eventName]=true;
      };
      service.emit = function (eventName, data, callback) {
        socket.emit(eventName, data, function () {
          var args = arguments;
          $rootScope.$apply(function () {
            if (callback) {
              callback.apply(socket, args);
            }
          });
        })
      };

      socket.on('newMsg', function (data) {
        //console.log("new msg")

        $rootScope.$emit('newMsg',data)

      });
      socket.on('newAlert', function (data) {

        //console.log("newAlert",data);
        $rootScope.$emit('newAlert',data)

      });
      socket.on('updateAlert', function (data) {


        $rootScope.$emit('updateAlert',data)

      });
      socket.on('changeDriver', function (data) {
        $rootScope.$emit('changeDriver',data)

      });
      socket.on('unauthorized', function (data) {
        service.closeListner();

      });



      socket.on('server:connected', function (data) {
        if(!$window.sessionStorage.token || !$window.sessionStorage.SESSION) {
          socket.disconnect();
          service.closeListner("newMsg", function () {
            console.log('close notification newMSg');
          });
          delete $window.sessionStorage.token;
          delete $window.sessionStorage.SESSION;


          //console.log(socket);
          service.closeListner("newMsg",function(){
            console.log('close notification newMSg');
          });
          console.warn('SESSION EXPIRED')
          $state.go("login");
          return ;
        }
        socket.emit('user:connected',{user:JSON.parse($window.sessionStorage.SESSION).user});

      });
      service.closeListner=function (eventName, callback) {
        socket.disconnect();
        socket.removeAllListeners(eventName, function() {
          var args = arguments;
          $timeout(function() {
            $rootScope.$apply(function () {
              callback.apply(socket, args);
            });

          })


        });

      };



      socket.on('connect', function (data) {

        $rootScope.RT_CONNECTED=true;
        service.isConnected = true;
      });
      socket.on('disconnect', function (data) {
        $rootScope.RT_CONNECTED=false;
        service.isConnected = false;


        $timeout(function() {
          $rootScope.$apply(function () {

          });
        });
        console.log("disconnect from RT")
      });
      socket.on("reconnecting", function(delay) {
        if(!$window.sessionStorage.token || !$window.sessionStorage.SESSION){
          socket.disconnect();
          service.closeListner("newMsg",function(){
            console.log('close notification newMSg');
          });

          $state.go("login");
          console.warn('SESSION EXPIRED')
          return ;
        }

        socket.io.opts.query='token='+$window.sessionStorage.token

      });
      socket.on("unauthorized", function(error) {
        console.log("unauthorized",delay, attempt)
      });

      socket.on('error', function (data) {
        //  Notification.error({title: ' ERROR ', message: 'Error RT'});
        if(data=="Not authorized")  {
          delete $window.sessionStorage.token;
          delete   $window.sessionStorage.SESSION;
          if(service.closeListner && typeof service.closeListner=="function")
                  service.closeListner();
          delete $window.sessionStorage.token;
          delete $window.sessionStorage.SESSION;


          //console.log(socket);
          service.closeListner("newMsg",function(){
            console.log('close notification newMSg');
          });

          $state.go("login");

        }

        console.log("connection error to RT",data)
      });
      service.off= function off(event, callback) {

        if (typeof callback === 'function') {
          var listener =eventListener[event+"_"+callback.name];
          socket.removeListener(event, listener);
        } else {
          socket.removeAllListeners(event);
        }

      };

      if (clb) clb(true)
    }
  };
  return service;

});
