/**
 * Created by ngi02 on 27/02/2018.
 */
angular.module('app').controller('devicesCtrl', function ($rootScope,$stateParams,deviceMsgStationSrv,deviceMsgUtils, $timeout, $scope,leafletData,stationSrv,$translate,$q,$state,deviceSrv, ngDialog) {

    $scope.device={};
    $scope.client={};
    $scope.filter={};

    $scope.filterList=['device','client','date'];
    $rootScope.mapLayers = {};
    $scope.currentPosition ={};
    var devicesMarkers = new L.MarkerClusterGroup();

    var  tday = moment().startOf("day").clone();
    var now  = moment().clone();

    $scope.startRes2 = tday;
    $scope.endRes2 = now;


    $scope.pickerStart2 = {
        format : "DD/MM/YYYY HH:mm",
        init: tday,
        position: "top left",
        autoTrans : false,
        enableTime : true,
        enableDate : true,
        enableMinutes : true,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.startRes2 = date;
            $scope.pickerEnd2.minDate = date;
            //  getData();
        }
    };

    $scope.pickerEnd2 = {
        format : "DD/MM/YYYY HH:mm",
        position: "top left",
        init: now,
        autoTrans : false,
        enableTime : true,
        enableDate : true,
        enableMinutes : true,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.endRes2 = date;
            $scope.pickerStart2.maxDate = date;
            //  getData();
        }
    };

    deviceSrv.getDevicesList({}, 100000, 0, function (data) {

        if (data && data.success && data.result) {

            var devices = data.result;

            $scope.deviceList= devices.map(function(val){
                return val._id;
            });


        } else {
            console.log(data.error)
        }
    }, function (err) {

    })

    $scope.openCalendar = function (e, picker) {
        $scope[picker].open = true;
    };

    $scope.position = [];


    $scope.map = {
        option: 'clusters'
    };


    angular.extend($scope, {
        events: {
            map: {
                enable: ['click', 'drag', 'blur','touchstart','popupclose','popupopen','zoomend','moveend'],
                logic: 'emit'
            }
        },
        defaults: {
            zoomControl: false,
            map: {
                minZoom: 3,
               //  maxZoom: 10,
                display: "none"

            }

        },
        center: {
            lat: 36.843602,
            lng: 10.206953,
            zoom: 3
        },
        markers: {},
        paths: {},
        controls: {},
        layers: {},
        tiles: {}

    });
    /**
     * Add controls to the map
     */
    $scope.scaleControle = L.control.scale({
        position: 'bottomleft',
        imperial: false,
        maxWidth: 150,
        updateWhenIdle: true
    });
    $scope.printControle = L.easyPrint({
        title: 'Imprimer la carte',
        position: 'topleft',
        elementsToHide: '.leaflet-top, .leaflet-bottom, .leaflet-left, .leaflet-right, .sidebarToggle, header, .card home-heading, .card dashboards'
    });
    $scope.zoomHomee = L.Control.zoomHome({position: 'topleft', zoomHomeIcon: "globe"});
    $scope.fullscreene = new L.Control.FullScreen({
        position: 'topleft', // change the position of the button can be topleft, topleft, bottomright or bottomleft, defaut topleft
        title: 'Map to fullscreen', // change the title of the button, default Full Screen
        titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
        content: '<i class="fa fa-arrows-alt" aria-hidden="true"></i>' // change the content of the button, can be HTML, default null
    });

    $scope.zoomSliderControle = new L.control.zoomslider({position: 'topleft'});

    /*Show stations markers*/
    $scope.findDevicesPosition=function (histoData) {
        leafletData.getMap("mapDevicerealtime").then(function (map) {
         //   map.eachLayer(function (layer) {
                map.removeLayer(devicesMarkers);
         //   });
           // map.invalidateSize();

        });
      //  $rootScope.mapLayers = {};


        // $scope.resetMap();


        var markersList = [];

        devicesMarkers.clearLayers();
        var content = '';

        var count2 = 0;
if(histoData && histoData.length>0){
        angular.forEach(histoData, function (device) {
           // console.log('data', device);
            var data = {};
            count2++;
            if (device && device._id && device.bs && device.lat && device.lng) {
                //console.log('count2',count2)
                var angle = 0;
                data = {"id": "", "type": "", "date": '', "ang": 0, 'lng': 0, 'lat': 0, 'red': 0, 'rssi': 0, 'seq': 0};

                if (device._id)
                    data.id = device._id;

                if (device.rssi)
                    data.rssi = device.rssi;

                if (device.seq)
                    data.seq = device.seq;

                if (device.type)
                    data.type = device.type;


                if (device.dt) {
                    data.date = moment(device.dt);
                }
                if (device) {
                    var count = 1;
                    if (device.bs2 && device.bs2 != null)
                        count++;
                    if (device.bs3 && device.bs3 != null)
                        count++;
                    if (device.bs4 && device.bs4 != null)
                        count++;

                    data.red = count;
                }


                if (device.lat && device.lng) {
                    data.lng = device.lng;
                    data.lat = device.lat;
                }


                if (data.lng !== 0 && data.lat !== 0) {
                    content = '<div class="map-popup"><div class="popup-heading fa fa-podcast"> ' + data.id + '</div><div class="popup-details">';

                    if (angular.isDefined(data.rssi) && data.rssi !== "") {
                        content += '<div  class="fa fa-rss"><b> ' + $translate.instant("RSSI") + ' : ' + '</b></div> ' + parseFloat(parseFloat(data.rssi).toFixed(2).toString()) + ' <br/>';
                    }
                    if (angular.isDefined(data.red) && data.red !== "") {
                        content += '<div  class="fa fa-mail-forward"><b> ' + $translate.instant("REDONDANCE") + ' : ' + '</b></div> ' + data.red + '<br/>';
                    }
                    if (angular.isDefined(data.seq) && data.seq !== "") {
                        content += '<div  class="fa fa-download"><b> ' + $translate.instant("SUCCESS") + ' : ' + '</b></div> ' + data.seq + '<br/>';
                    }
                    if (angular.isDefined(data.type) && data.type !== "") {
                        content += '<div  class="fa fa-hdd-o"><b> ' + $translate.instant("TYPE") + ' : ' + '</b></div> ' + data.type + ' <br/>';
                        // content += '<div  class="fa fa-hdd-o"><b> ' + $translate.instant("TYPE")+' : '+ '</b></div> ' + data.type + ' dbm <br/>';
                    }
                    if (angular.isDefined(data.date) && data.date !== "") {
                        content += '<div  class="fa fa-clock-o"><b> ' + $translate.instant("DATE") + ' : ' + '</b></div> ' + moment(data.date).format('DD/MM/YYYY HH:mm:ss') + '<br/>';
                    }
                    if (angular.isDefined(data.address) && data.address !== "") {
                        content += '<div  class="fa fa-map-marker"><b> ' + $translate.instant("ADDRESS") + ' : ' + '</b></div> ' + data.address;
                    }
                    content += '</div></div></div>';
                    function myFunction(a) {
                        console.log('ok', a)
                    }

                    var deviceIcon = L.icon({
                        iconUrl: 'app/assets/images/dev3.png',
                        html: data.id,
                        iconSize: [20, 30],
                        iconAnchor: [18, 60] //+10 +10
                    });
                    var marker = new L.Marker(new L.LatLng(data.lat, data.lng),
                        {
                            iconAngle: angle,
                            icon: deviceIcon
                        }).bindPopup(content);
                    devicesMarkers.addLayer(marker);
                    markersList.push(marker)
                }
            }
        });   // end for each
    }
                var deviceLatLngs = [];
                for (var i = 0; i < markersList.length; i++) {
                    var latLng = markersList[i].getLatLng();
                    deviceLatLngs.push(latLng);
                }
                if (deviceLatLngs.length > 0) {
                      console.log('stationlatlng',deviceLatLngs)
                    leafletData.getMap("mapDevicerealtime").then(function (map) {

                        $timeout(function () {
                            map.addLayer(devicesMarkers);
                        });

                        if (deviceLatLngs.length === 1) {
                            map.setView(deviceLatLngs[0], 18);
                        }
                        else {
                            $timeout(function () {
                                var bounds = new L.LatLngBounds(deviceLatLngs);
                                map.fitBounds(bounds);
                            })
                        }
                    });


                }else{
                    leafletData.getMap("mapDevicerealtime").then(function (map) {

                        map.invalidateSize();
                    });
                }



    };

    leafletData.getMap("mapDevicerealtime").then(function (map) {

        map.addControl($scope.zoomSliderControle);
        map.addControl($scope.zoomHomee);
        map.addControl($scope.fullscreene);
        //  map.addControl($scope.printControl);
        map.addControl($scope.scaleControle);

        map.on('popupopen', function (e) {
            e.latlng = new L.LatLng(e.popup._latlng.lat, e.popup._latlng.lng);
            $scope.currentPosition = e;
        });
        map.on('moveend', function () {
            if (map.getZoom() > 14) {
                $scope.layers['overlays'] = $rootScope.mapLayers.overlays;
              //  $rootScope.mapLayers.overlays.geoData.visible = true;
            } else {
               // $rootScope.mapLayers.overlays.geoData.visible = false
            }
        });
        map.on('enterFullscreen', function () {
            $('.leaflet-control-easyPrint ').hide();
            var elem = $('base-layer-directive');
            elem.css({
                "z-index": 9999999991,
                "position": "absolute",
                "top": 0,
                "right": 0
            });
        });
        map.on('exitFullscreen', function () {
            $('.leaflet-control-easyPrint ').show();
            var elem = $('base-layer-directive');
            elem.attr("style", "");
        });
    });

    $scope.filterData=function(){


        console.log('dev',$scope.deviceId);
        console.log('cc',$scope.client);
        console.log('fil',$scope.filter);
        console.log('fil', $scope.startRes2,$scope.endRes2);




        var Query=getQuery($scope.startRes2,$scope.endRes2,$scope.filter);

        $scope.getMsgsData(Query);


    }
    $scope.resetFilterFields=function(){

        console.log('change filter')

        $scope.filter={name:$scope.filter.name,param:''};


    }

    var getQuery=function(startDt,endDt,filter) {


        var startDate = moment.utc(startDt);
        var endDate = moment.utc(endDt);

      //  var agg = deviceMsgUtils.BuildQueryByPeriod(startDate, endDate);

var  match={};
console.log('filter',filter)

        if(filter && filter.name=='device' && filter.param!=''){

           match= {
                "device_id": filter.param
                      }
        }else if(filter && filter.name=='client'){
            match= {
                "operator": {'$regex': '^'+filter.param, '$options' : 'i'}
            }

        }else if(filter && filter.name=='date' && startDate !=null && endDate !=null){
            match= {
                time_stamp: {
                 $gte: '#ISODATE#'+startDate.toDate().toISOString()+'#ISODATEEND#',
                 $lte: '#ISODATE#'+endDate.toDate().toISOString()+'#ISODATEEND#'
                 }
            }

        }




        var query =
            [
                {
                    "$match": match
                },                {
                $lookup: {
                    from: "station",
                    localField: "station1_id",
                    foreignField: "id",
                    as: "station"
                }
            }, {
                $lookup: {
                    from: "device",
                    localField: "device_id",
                    foreignField: "_id",
                    as: "device"
                }
            },
                {
                    "$project":{
                        "mydev": "$device_id",
                        "bs1": "$station1_id",
                        "bs": {$arrayElemAt: ["$station", 0]},
                        "dev": {$arrayElemAt: ["$device", 0]},
                        "bs2": "$station2_id",
                        "bs3": "$station3_id",
                        "bs4": "$station4_id",
                        "dt": "$time_stamp",
                        'rssi':"$rssi1",
                        'seq':"$Seq_numb"
                    }
                },
                {
                    $group: {
                        _id: "$mydev",


                        "bs" : {"$last": "$bs1"},
                        "lng" : {"$last": "$bs.lng"},
                        "lat" : {"$last": "$bs.lat"},
                        "bs2" :{"$last": "$bs2"},
                        "bs3": {"$last": "$bs3"},
                        "bs4" : {"$last": "$bs4"},
                        "dt" : {"$last": "$dt"},
                        "rssi" : {"$last": "$rssi"},
                        "seq" : {"$last": "$seq"},
                        "type":{"$last": "$dev.model"}

                    }

                },
               // {"$sort": {"_id.date": 1}}
                {"$sort": {"time_stamp": 1}}
                //{"$sort": {"station1_id": 1}}

            ]


        return query
    };


    $scope.getMsgsData=function(query){
       // console.log('query',JSON.stringify(getQuery()))
        var dataMSGs=[];

       if(query &&  query!=null)
        var myQuery=query;
        else
            myQuery=getQuery();


        console.log('myQuery',myQuery)


        deviceMsgStationSrv.getDeviceMsgStationAgg2({aggregate:myQuery},10000,0, function (res) {

       // console.log('getting data',res.result);
        if (res && res.result &&  res.result.length > 0) {
            dataMSGs=angular.copy(res.result);

            $scope.findDevicesPosition(dataMSGs);
 /*           dataMSGs.forEach(function (value) {
                console.log(value)
            })*/

        }else{
            $scope.findDevicesPosition([]);


    }
    }, function (err) {

        if (err && err.status === -1) {
            Notification.error("Probl�me de connexion Internet");
        }
        console.error("Device message error ", err);
            $scope.findDevicesPosition([]);

        }, true)

    }





$scope.getMsgsData();

});