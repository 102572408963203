angular.module("app.directives").directive("toolsDirective", function ($rootScope,$window,userSrv) {
    return{
        scope:{
            map:'@'
        },
        templateUrl: "app/commons/directives/map/tools/tools.drv.html",
        link: function (scope) {



            scope.loadLayers = function () {
                if (angular.isDefined($rootScope.mapLayers.overlays) && Object.keys($rootScope.mapLayers.overlays).length === 0) {

                    $rootScope.mapLayers.overlays = {
                        geoData: {
                            name: 'Pois / Zones',
                            type: 'geoJSONShape',
                            visible: false,
                            zIndex: 100,
                            layerOptions: {
                                zIndex: 100
                            }
                        }
                    }

                }
                scope.mapLayers = $rootScope.mapLayers;
            };
            scope.loadLayers();



            scope.isPopupVisible = false;
            scope.togglePopup = function(){
                scope.isPopupVisible = !scope.isPopupVisible;
            };


            $(document).click(function(event) {
                if(!$(event.target).closest('#togglePopupMap').length) {
                    scope.isPopupVisible = false;
                }
            });

            var defaultParams = {
                mapzoom: true,
                mappan: true,
                address_date: true,
                sensors: false
            };
            var userSession = JSON.parse($window.sessionStorage.SESSION).user;
            if (userSession && userSession.params) {
                if (userSession.params.views_config) {
                    scope.mapParams = userSession.params.views_config;
                    scope.mapParams.mapzoom = userSession.params.views_config.mapzoom;
                    scope.mapParams.mappan = userSession.params.views_config.mappan;
                }
                else {
                    scope.mapParams = defaultParams;
                    userSession.params.views_config = scope.mapParams;
                    $rootScope.SESSION.user = userSession;
                    $window.sessionStorage.SESSION = JSON.stringify($rootScope.SESSION);
                }

            }
            else {
                scope.mapParams = defaultParams;
                userSession.params = {};
                userSession.params.views_config = defaultParams;
                $rootScope.SESSION.user = userSession;
                $window.sessionStorage.SESSION = JSON.stringify($rootScope.SESSION);
            }

            scope.updateMapParams = function () {
                userSrv.updateUserParams(['mapzoom', 'mappan'], scope.mapParams);
            };


        }
    }
});