angular.module("app.directives").directive("baseLayerDirective", function ($rootScope,$state,leafletData) {
    return{
        scope:{
            map:'@'
        },
        templateUrl: "app/commons/directives/map/layers/base.drv.html",
        link: function (scope) {
            scope.mapLayers = {
                baselayers: {
                    googleHybrid: L.tileLayer('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
                        maxZoom: 19,
                        name: "GOOGLE_HYB",
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        icon: 'app/commons/directives/map/images/gHybr.png'

                    }),
                    NGI: L.tileLayer.wms($rootScope.config.LBS_WMS, {
                        maxZoom: 19,
                        name: "NGI",
                        attribution: 'Powered by iaxeed',
                        icon: 'app/commons/directives/map/images/ngiMap.png'
                    }),
                    googleRoadmap: L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                        maxZoom: 19,
                        name: "GOOGLE_NOR",
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        icon: 'app/commons/directives/map/images/gNorm.png'

                    }),

                    googleTerrain: L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                        maxZoom: 19,
                        name: "GOOGLE_SAT",
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        icon: 'app/commons/directives/map/images/gSat.png'

                    }),

                    osm: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        maxZoom: 19,
                        name: "OPEN_STREET",
                        icon: 'app/commons/directives/map/images/osmMap.png'

                    })
                },
                overlays: {
                    geoData : {
                        name: 'Pois / Zones',
                        type: 'geoJSONShape',
                        visible: false,
                        zIndex:100,
                        layerOptions:{
                            zIndex:100
                        }

                    }


                }
            };
            $rootScope.mapLayers = scope.mapLayers;
            scope.initMap = function () {
                leafletData.getMap(""+scope.map).then(function (map) {
                    map.options.attributionControl = true;
                    map.attributionControl.setPrefix('');
                    map.whenReady(function () {
                        if($rootScope.selectedBaseLayerKey){
                            scope.changeBaseLayer($rootScope.selectedBaseLayerKey);
                        }else{
                            scope.changeBaseLayer(Object.keys($rootScope.mapLayers.baselayers)[0]);
                        }
                    });
                })
            };

            scope.initMap();

            scope.changeBaseLayer = function (key) {
                leafletData.getMap(""+scope.map).then(function (map) {
                    for (var k in $rootScope.mapLayers.baselayers) {
                        var layer = $rootScope.mapLayers.baselayers[k];
                        if (map.hasLayer(layer)){
                            map.removeLayer(layer);
                        }
                    }
                    $rootScope.selectedBaseLayerKey = key;
                    map.addLayer($rootScope.mapLayers.baselayers[key]);


                });
                $rootScope.$emit('closeRightSidebar');
            };

            scope.isActive = function (key) {
                return key === $rootScope.selectedBaseLayerKey;
            };

        }
    }
});