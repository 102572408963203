angular.module('app').controller('mapCtrl', function ($rootScope,$stateParams, $scope,leafletData,stationSrv,$translate,$timeout,$q,$state, ngDialog) {

    $rootScope.mapLayers = {};
    $scope.currentPosition ={};
    var stationMarkers = new L.MarkerClusterGroup();

    $scope.position = [];


    $scope.map = {
        option: 'clusters'
    };


    angular.extend($scope, {
        events: {
            map: {
                enable: ['click', 'drag', 'blur','touchstart','popupclose','popupopen','zoomend','moveend'],
                logic: 'emit'
            }
        },
        defaults: {
            zoomControl: false,
            map: {
                minZoom: 3,
               // maxZoom: 16,
                display: "none"

            }

        },
        center: {
            lat: 36.843602,
            lng: 10.206953,
            zoom: 3
        },
        markers: {},
        paths: {},
        controls: {},
        layers: {},
        tiles: {}

    });
    /**
     * Add controls to the map
     */
    $scope.scaleControl = L.control.scale({
        position: 'bottomleft',
        imperial: false,
        maxWidth: 150,
        updateWhenIdle: true
    });
    $scope.printControl = L.easyPrint({
        title: 'Imprimer la carte',
        position: 'topleft',
        elementsToHide: '.leaflet-top, .leaflet-bottom, .leaflet-left, .leaflet-right, .sidebarToggle, header, .card home-heading, .card dashboards'
    });
    $scope.zoomHome = L.Control.zoomHome({position: 'topleft', zoomHomeIcon: "globe"});
    $scope.fullscreen = new L.Control.FullScreen({
        position: 'topleft', // change the position of the button can be topleft, topleft, bottomright or bottomleft, defaut topleft
        title: 'Map to fullscreen', // change the title of the button, default Full Screen
        titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
        content: '<i class="fa fa-arrows-alt" aria-hidden="true"></i>' // change the content of the button, can be HTML, default null
    });

    $scope.zoomSliderControl = new L.control.zoomslider({position: 'topleft'});

    leafletData.getMap("maprealtime").then(function (map) {


        map.addControl($scope.zoomSliderControl);
        map.addControl($scope.zoomHome);
        map.addControl($scope.fullscreen);
        //  map.addControl($scope.printControl);
        map.addControl($scope.scaleControl);


        map.on('popupopen', function (e) {
            e.latlng = new L.LatLng(e.popup._latlng.lat, e.popup._latlng.lng);
            $scope.currentPosition= e;

        });
        map.on('moveend', function () {
            if (map.getZoom() > 14) {
                $scope.layers['overlays'] = $rootScope.mapLayers.overlays;
                //  $rootScope.loadPois();
              //  $rootScope.mapLayers.overlays.geoData.visible = true;

            } else {
              //  $rootScope.mapLayers.overlays.geoData.visible = false
            }
        });
        map.on('enterFullscreen', function(){
            $('.leaflet-control-easyPrint ').hide();
            var elem = $('base-layer-directive');
            elem.css({
                "z-index" : 9999999991,
                "position" : "absolute",
                "top" : 0,
                "right": 0
            });
        });

        map.on('exitFullscreen', function(){
            $('.leaflet-control-easyPrint ').show();
            var elem = $('base-layer-directive');
            elem.attr("style","");
        });


    });
        /*Show stations markers*/
    $scope.findStationsPosition=function () {

       // $scope.resetMap();


        var markersList = [];
        stationMarkers.clearLayers();
        var content = '';

        stationSrv.getStationsList ({}, Number.MAX_SAFE_INTEGER, 0, function (data) {

            if (data && data.success) {

           $scope.stations=data.result;

        angular.forEach($scope.stations, function (station) {
           // console.log('data',station)

            var data = {};


                if (station && station._id) {
                    var angle = 0;
                    data = {
                        "id":"",
                        "name": "",
                        "type" : "",
                        "date": '',
                        "ang": 0,
                        'lng': 0,
                        'lat': 0,
                        'address':"",
                        'con':"",
                        'signal':0

                    };
                    if(station.name)
                    data.name = station.name;

                    if(station.id)
                    data.id = station.id;

                    if(station.connection)
                        data.con = station.connection;

                    if(station.model)
                        data.type = station.model;

                        if (station.creation_dt) {
                            data.date = moment(station.creation_dt);
                        }

                        if (station.power) {
                                data.signal = station.power;

                        }
                        if (station.lat && station.lng) {
                                data.lng = station.lng;
                                data.lat = station.lat;

                        }
                    if (station.site)
                        data.address = station.site;


                    if (data.lng !== 0 && data.lat !== 0) {
                        content = '<div class="map-popup"><div class="popup-heading fa fa-podcast"> ' + data.name + '</div><div class="popup-details">';
                        if(angular.isDefined(data.date) && data.date !== ""){
                            content += '<div  class="fa fa-clock-o"><b> '+$translate.instant("DATE")+'</b></div> ' + moment(data.date ).format('DD/MM/YYYY HH:mm:ss')+ '<br/>';
                        }
                        if(angular.isDefined(data.con) && data.con !== ""){
                            content += '<div  class="fa fa-wifi"><b> '+$translate.instant("CONNEXION")+'</b></div> ' + data.con + '<br/>';
                        }
                        if(angular.isDefined(data.signal)){
                            content += '<div  class="fa fa-tachometer"><b> '+$translate.instant("POWER")+'</b></div> ' + parseFloat(parseFloat(data.signal).toFixed(2).toString()) + ' RSSI<br/>';
                        }
                        if(angular.isDefined(data.address) && data.address !== ""){
                            content += '<div  class="fa fa-map-marker"><b> '+$translate.instant("ADDRESS")+'</b></div> ' + data.address;
                        }

/*
                        content += '<br><CENTER><a href="app/home/statics/'+station._id+'" > <div  class="fa fa-eye"> <b><u> ' + $translate.instant("SHOW_STATISTICS")+'</u></b></div></a></CENTER>';
*/

                         /* content += '<div align="right"><img src="./app/commons/directives/map/images/start_flag_16.png" style="cursor: pointer;padding-right: 1em;"  title="'+$translate.instant("STARTING_POINT")+'">'+
                              '<img src="./app/commons/directives/map/images/finish_flag_16.png" style="cursor: pointer;" onClick="getFinish()" title="'+$translate.instant("ARRIVAL_POINT")+'"></div></div>';*/

                        content += '</div></div></div>';

                        function myFunction(a) {
console.log('ok',a)                        }

                            var stationIcon = L.icon({
                                iconUrl:'app/assets/images/antenna.png',
                                html: data.name,
                                iconSize: [50, 60],
                                iconAnchor: [18, 60]
                            });



                        var marker = new L.Marker(new L.LatLng(data.lat,data.lng),
                            { iconAngle: angle,
                                icon:stationIcon
                            }).bindPopup(content);

                      //  L.marker.prptotype
                     //   var label = "<span class='tooltip-name'>"+data.name+"</span>";
                      //  if(Number.isFinite(data.signal))
                      //      label += "<br><span class='tooltip-speed'>"+ data.signal+ " RSSI</span>";*/
                       // marker.bindTooltip(label, { direction:'right', permanent: true ,offset: [15, 0] ,className: 'tooltip-marker' });

                        stationMarkers.addLayer(marker);

                        markersList.push(marker)
                      //  $scope.position.push(data);



                    }



                }
        });

        var stationLatLngs = [];
        for (var i = 0; i < markersList.length; i++) {
            var latLng = markersList[i].getLatLng();
            stationLatLngs.push(latLng);

        }


                if (stationLatLngs.length > 0) {

        //  console.log('stationlatlng',stationLatLngs)
                    leafletData.getMap("maprealtime").then(function (map) {

                         $timeout(function(){
                         map.addLayer(stationMarkers);
                         },100);



                        if( stationLatLngs.length===1){
                            map.setView(stationLatLngs[0], 18);
                        }
                        else{
                            $timeout(function(){

                                var bounds = new L.LatLngBounds(stationLatLngs);
                                map.fitBounds(bounds);
                                },500)

                        }

                        if(map){
                            return map._leaflet_pos || new Point(0, 0);
                        }
                        else{
                            return new Point(0, 0);
                        }

                    });


        }

            } else {
                console.error(data.error)
            }
        }, function (data) {
            console.log(data)
        })

    };

    $scope.showSationStat=function(station){
         console.log('clicked')
        if(station && station._id){
           // $rootScope.$emit('stationStatistiques',station)
            console.log('go to bts stat',station)

        if ($state.current.name === "app.home.map") {
            $state.go('app.home.statics',{statId:station._id});

        }
        }
    }

    $scope.findStationsPosition();
});