angular.module("app.directives").directive("bndInstructions",  function ($q, geoServiceProxy, $sce, $rootScope) {

    return {
        restrict: "EA",
        scope: {
            route: '=',
            centerInstruction: '=',
            steps: "="
        },
        template: '<ul class="list-group" >' +
        '<li style="font-size: 13px" ng-click="zoomToPosition(instruction.Coordinate)" style="cursor:pointer;" class="list-group-item" bnd-road-instruction ng-repeat="instruction in route.Instructions.Instruction" road-instruction="instruction" ng-bind-html="instruction.htmlContent"></li>' +
        '</ul>',
        link: function (scope) {
            scope.$watch('route', function (oldval, newVal) {
                //console.log('changed instruction %o', newVal);
            });
            scope.zoomToPosition = function (coordinate) {
                if (coordinate !== undefined) {
                    $rootScope.$broadcast('poi:zoom', {lng: coordinate.x, lat: coordinate.y});
                }
            }
        }

    }
});
