angular
    .module('app')
    .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/login");

    $stateProvider
        .state('authEndpoint', {
            url: '/auth-endpoint',
            template:"<div>Authentication success from oAuth </div>"
        }).state('login', {
            url: '/login',
            templateUrl: 'app/pages/login/login.tpl.html',
            controller: 'loginCtrl'
        })
        .state('subscribe', {
            url: '/subscribe',
            templateUrl: 'app/pages/subscribe/subscribe.tpl.html',
            controller: 'subscribeCtrl'
        })
        .state('app', {
            url: '/app',
            templateUrl: 'app/pages/main.tpl.html',
            controller : 'mainCtrl',
            abstract: true
        }).state('app.home', {
            url: '/home',
            views: {
                "sidebar": {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"


                },
                "content": {
                   // templateUrl: "app/pages/home/home.tpl.html",
                    template: "<ui-view></ui-view>",
                    controller: "homeCtrl"
                }

            }
        }).state('app.home.map', {
                url: '/map',
                templateUrl: "app/pages/home/mapStations/map.tpl.html",
                controller: "mapCtrl"

      }).state('app.home.stat', {
            url: '/stat',
            templateUrl: "app/pages/home/stat/stat.tpl.html",
             controller: "statCtrl"


     }).state('app.home.statics', {
                url: '/stat/:statId',
                templateUrl: "app/pages/home/stat/stat.tpl.html",
                controller: "statCtrl"

     }).state('app.home.devices', {
                url: '/devices',
                templateUrl: "app/pages/home/mapDevices/devices.tpl.html",
                controller: "devicesCtrl"
    }).state('app.home.degSites', {
                url: '/degSites',
                templateUrl: "app/pages/home/degSites/degSites.tpl.html",
                controller: "degSitesCtrl"
    })



}
