/**
 * Created by Kais on 31/03/2015.
 */
angular.module("app.directives").directive("routingStep", ['$q', 'geoServiceProxy', '$sce', '$rootScope', function ($q, geoServiceProxy, $sce, $rootScope) {

    return {
        restrict: "EA",
        scope: {
            stepType: '=',
            point: "="

        },
        templateUrl: "app/commons/directives/map/itineraire/steps/routingSteps.tpl.html",
        link: function (scope, element) {
            element[0].focus();
            scope.step = {detailed: true};
            scope.updateShowDetailed = function (value) {
                scope.step.detailed = value;
                $rootScope.$emit('updatedShowDetails', element);
            };

            $rootScope.$on('updatedShowDetails', function (e, elem) {
                if(elem !== element){
                    scope.step.detailed = true;
                }
            });

            $(document).on('click', function (e) {
                var inputs = $('.right');
                var isChildOfInputs = inputs.find(e.target).length > 0;

                if(isChildOfInputs)
                    return;

                scope.$apply(function () {
                    scope.step.detailed = true;
                });
            });

            scope.$watch('step.detailed', function (oldValue, newValue) {
                //console.log(element[0].querySelector('.city'));
                if (newValue === true) {
                    setTimeout(function () {
                            element[0].querySelector('.city').focus();
                        }
                        , 10);
                }
            });
            scope.getAddress = function (point) {
                var address="";
                if(point.addressDetails && point.addressDetails.street)
                    address= point.addressDetails.street;
                if(point.addressDetails && point.addressDetails.district){
                    if(address==="")
                        address = point.addressDetails.district;
                    else
                        address += ", " + point.addressDetails.district;

                }
                if(point.addressDetails && point.addressDetails.city){
                    if(address==="")
                        address = point.addressDetails.city;
                    else
                        address += ", " + point.addressDetails.city;

                }
                return address
            };

            scope.address = {};
            scope.Cityautocomplete_options = {
                suggest: function (term) {
                    if(term.length>=3){
                        var defer = $q.defer();
                        geoServiceProxy.geoCodeAddress(10, 'Tunisie', term, null, null, "fr", "", "", "", "").then(function (res) {
                            result = [];
                            res.elements.forEach(function (value) {
                                result.push({
                                    label: value.addressDetails.city,
                                    "value": value.addressDetails.city,
                                    obj: value
                                });
                            });
                            defer.resolve(result)

                        }, function (error) {
                            alert(error.errorCode + " \r\n" + error.errorString + " \r\n" + JSON.stringify(error.errorDetails));

                        });
                        return defer.promise;
                    }
                },
                on_error: console.log
            };
            scope.streetAutocomplete_options = {
                suggest: function (term) {
                    var defer = $q.defer();
                    geoServiceProxy.geoCodeAddress(10, 'Tunisie', scope.point.addressDetails.city, term, null, "fr", "", "", "", "").then(function (res) {
                        result = [];
                        res.elements.forEach(function (value) {
                            result.push({
                                label: $sce.trustAsHtml(
                                    '<span>' + value.addressDetails.street + ' ' + value.addressDetails.city + '</span>'),
                                "value": value.addressDetails.street,
                                obj: value
                            });
                        });
                        defer.resolve(result)

                    }, function (error) {
                        alert(error.errorCode + " \r\n" + error.errorString + " \r\n" + JSON.stringify(error.errorDetails));

                    });
                    return defer.promise;
                },
                on_error: console.log,
                on_select: function (selected_item) {
                    //console.log(selected_item);
                    scope.point = selected_item.obj;

                    scope.updateShowDetailed(true);

                }
            };
        }
    }
}]);
