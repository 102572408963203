/**
 * Created by ngi02 on 27/02/2018.
 */
angular.module('app').controller('statCtrl', function ($rootScope,$stateParams, $timeout, $scope,deviceMsgStationSrv,deviceMsgUtils,stationSrv,$translate,$q,$state, ngDialog) {

    $scope.station={};
    $scope.periodList=['day','hour','minute','second'];
    $scope.echantionage={};

    $scope.countUplink=0;
    $scope.countDownlink=0;
    $scope.countDEV=0;
    $scope.countDEV_BEST_RSSI=0;
    $scope.countDEV_1_BTS=0;
    $scope.rateDISP=0;
    $scope.countUP=0;
    $scope.bestRSSI = 0;


    $scope.distinctDEV = [];

$scope.searchforData=false;

    stationSrv.getStationsList({}, 100000, 0, function (data) {

        if (data && data.success && data.result) {

            $scope.stations = data.result;

               $scope.stationsIDs= $scope.stations.map(function(val){
                return val.id;
            });
            $scope.stationsSiteIDs= $scope.stations.map(function(val){
                return val.site_id;
            });

        } else {
            console.log(data.error)
        }
    }, function (err) {

    })

if($stateParams.statId) {
    console.log('$scope.station',$scope.station)

    stationSrv.getStationsList({
        query:{_id:$stateParams.statId},
        options:{}

    }, 1, 0, function (data) {
        console.log('$scope.station',data)

        if (data && data.success && data.result) {

            $scope.station = angular.copy(data.result[0]);
            $scope.echantionage.name='day'
            $scope.getHistoData();

console.log('$scope.station',$scope.station)
        } else {
            console.log(data.error)
        }
    }, function (err) {

    })
}

    /*catch click to link of popup station in the map*/
     $rootScope.$on('stationStatistiques',function(event,data){

            console.log('go to bts stat',data)
        $scope.station=angular.copy(data);

         var id=$scope.station._id;

        // $scope.loadDataDeviceMsg2(id);

    });



    var  tday = moment().startOf("day").clone();
    var now  = moment().clone();

    $scope.startRes = tday;
    $scope.endRes = now;


    $scope.pickerStart = {
        format : "DD/MM/YYYY HH:mm",
        init: tday,
        position: "top left",
        autoTrans : false,
        enableTime : true,
        enableDate : true,
        enableMinutes : true,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.startRes = date;
            $scope.pickerEnd.minDate = date;
          //  getData();
        }
    };

    $scope.pickerEnd = {
        format : "DD/MM/YYYY HH:mm",
        position: "top left",
        init: now,
        autoTrans : false,
        enableTime : true,
        enableDate : true,
        enableMinutes : true,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.endRes = date;
            $scope.pickerStart.maxDate = date;
          //  getData();
        }
    };



    $scope.openCalendar = function (e, picker) {
        $scope[picker].open = true;
    };

    $scope.getData = function () {

        console.log('get echantionage',$scope.echantionage);
        console.log('get data',$scope.station);


if($scope.station &&  $scope.station.id && $scope.startRes!=null && $scope.endRes!=null)
        {


            $scope.getHistoData();

         //   $scope.loadDataDeviceMsg2($scope.station);
        }else{
    $scope.searchforData=false;

}


    };

    /**
     * Prepare query
     * @param startDt
     * @param endDt
     * @returns {{query: *[]}}
     */
     var getQuery = function (startDt, endDt) {


        var startDate = moment.utc(startDt || $scope.startRes);
        var endDate = moment.utc(endDt || $scope.endRes);

        if($scope.echantionage.name && $scope.echantionage.name=='second'){
            var agg = deviceMsgUtils.getSecondsQuery();

        }else if($scope.echantionage.name && $scope.echantionage.name=='minute'){
            var agg = deviceMsgUtils.getMinuteQuery();

        }else if($scope.echantionage.name && $scope.echantionage.name=='hour'){
            var agg = deviceMsgUtils.getHourlyQuery();

        }else if($scope.echantionage.name && $scope.echantionage.name=='day'){

            var agg = deviceMsgUtils.getDailyQuery();

        }else{
            //$scope.echantionage.name=='custom';
            //var agg = deviceMsgUtils.BuildQueryByPeriod(startDate, endDate);
            var agg = deviceMsgUtils.getDailyQuery();

          //  var agg = deviceMsgUtils.BuildQueryByPeriod(startDate, endDate);

        }



     var  query =     [
                {
                    "$match": {
                        "station1_id": $scope.station.id,
                        "time_stamp": {
                            $gte: '#ISODATE#'+$scope.startRes.toDate().toISOString()+'#ISODATEEND#',
                            $lte: '#ISODATE#'+$scope.endRes.toDate().toISOString()+'#ISODATEEND#'

                        }

                    }
                }, {
                    "$project": {
                       // "date1": "$time_stamp",
                        "device_id": "$device_id",
                        "ack": "$ack",
                        "lnk_qlty": "$lnk_qlty",
                        "rssi1": "$rssi1",
                        "signal1": "$signal1",
                        "station1_id": "$station1_id",
                        "station2_id": "$station2_id",
                        "station3_id": "$station3_id",
                        "station4_id": "$station4_id",
                        "time_stamp": {"$add": ["$time_stamp", moment().utcOffset() * 60 * 1000]},
                        "date": agg.project
                    }
                },
                {
                    "$group": {
                        "_id": agg.group,
                        "data": {
                            $push: {
                                "dev": "$device_id",
                                "ack": "$ack",
                                "lnk_qlty": "$lnk_qlty",
                                "rssi1": "$rssi1",
                                "signal1": "$signal1",
                                "station1": "$station1_id",
                                "station2": "$station2_id",
                                "station3": "$station3_id",
                                "station4": "$station4_id",
                                "dt": "$time_stamp"
                            }
                        },
                        "count": {"$sum": 1}
                    }
                },
                {"$sort": {"_id": 1}}


            ]
        return query;

    };


    /**
     * Get hist data
     */
    $scope.getHistoData = function () {
console.log('call getHistoData')
        $scope.histoData = [];




        deviceMsgStationSrv.getDeviceMsgStationAgg2({aggregate:getQuery()},1000000,0, function (res) {

            var unique = {};

            var msgsCount=0;
            console.log('res agg',res)

            var ackDataTrue = [];
            var ackDataFalse = [];
            var devData = [];
            var devOneBts = [];
            var devBestRssi = [];
            var devRedondancyBts = [];

                if (res && res.result && res.result.length > 0) {

                    res.result.forEach(function (value) {
                        var countUplink=0;
                        var countDownlink=0;
                        var bestRSSI = 0;
                        var countOneBts=0;
                        var countRedondancyBts=0;

                        var distinctDEV = [];

var signalHisto=[];


                        msgsCount++;
                      //  var myDate = value._id.m+'/'+value._id.d+'/'+value._id.y+' '+value._id.h+':'+(value._id.mm||'00')+':'+(value._id.ss||'00');
                       // var _date=//moment(myDate,"MM/DD/YYYY HH:mm:ss").valueOf();
                     var _date=moment({ year :value._id.y,
                         month :value._id.m-1,
                         day :value._id.d,
                         hour :value._id.h || 0,
                         minute :value._id.mm || 0,
                         second :value._id.ss || 0,
                         millisecond :0});



                /*        moment({
                            y: value._id.y,
                          //  M: value._id.m - 1,
                            M: value._id.m,
                            d: value._id.d,
                            h: value._id.h,
                            m: value._id.mm,
                            s: value._id.ss || 00
                        });*/

                  console.log('_date',_date,_date.isValid())
                      //  var row = [Date.parse(moment(message.gps_dt, "YYYY-MM-DD HH:mm:ss").toDate()), val];
                     //   $scope.data.push(row);



                        if (value && value.data && value.data.length > 0) {



                            var elemTab = value.data;

                             elemTab.forEach(function (elem) {
                               //  $scope.histoData.push(elem)


                                 if (Number.isFinite(elem.signal1)) {
                                //signalData.push([Date.parse(moment(_date.valueOf(),"YYYY-MM-DD HH:mm:ss").toDate()), parseFloat(elem.signal1)]);
                                signalHisto.push(elem.signal1);


                            }

                            if (!unique[elem.dev]) {
                                distinctDEV.push(elem.dev);
                                unique[elem.dev] = true;

                                if (elem.lnk_qlty == 'Excellent') {
                                    bestRSSI++;
                                                                   }

                            }

                            if (elem.station1 === $scope.station.id && (elem.station2 === '' || !elem.station2) && (elem.station3 === '' || !elem.station3) && (elem.station4 === '' || !elem.station4)) {
                                countOneBts++;
                            }
                            if (elem.station1 === $scope.station.id && ( elem.station2 !== '' || elem.station3 !== '' || elem.station4 !== '')) {
                                countRedondancyBts++;
                            }

                            if (elem.ack == false) {
                                countUplink++;
                            }

                            if (elem.ack == true) {
                                countDownlink++;

                            }

                        })


                            ackDataTrue.push([_date.valueOf(), countUplink])
                            ackDataFalse.push([_date.valueOf(), countDownlink])
                            devRedondancyBts.push([_date.valueOf(), countRedondancyBts]);
                            devData.push([_date.valueOf(), distinctDEV.length]);
                            devBestRssi.push([_date.valueOf(), bestRSSI]);
                            devOneBts.push([_date.valueOf(), countOneBts]);
                           // signalData.push([_date.valueOf(), parseFloat(elem.signal1)]);
                           // signalData.push([_date.valueOf(), signalHisto]);

                        }
                    });

                    /* call chart methodes */

                    var idChart='msgUpChart'; var ctitle='Msgs UpLink';  var ytitle='msgs'; var nameS='Nbre msgs UP';
                    graphLoad(idChart,ctitle,ytitle,nameS,ackDataTrue);

                    idChart='msgDownChart';  ctitle='Msgs DownLink';   ytitle='msgs';  nameS='Nbre msgs Down';
                    graphLoad(idChart,ctitle,ytitle,nameS,ackDataFalse);

                    idChart='devNumberChart';  ctitle='Nbre Devices';   ytitle='devices';  nameS='Nbre devices';
                    graphLoad(idChart,ctitle,ytitle,nameS,devData);

                    idChart='bestRssiChart';  ctitle='Best RSSI Devices';   ytitle='devices';  nameS='Nbre devices';
                    graphLoad(idChart,ctitle,ytitle,nameS,devBestRssi);


                    idChart='avgRedondancyChart';  ctitle='AVG redondancy';   ytitle='avg';  nameS='redondancy msgs';
                    graphLoad(idChart,ctitle,ytitle,nameS,devRedondancyBts);

                    idChart='devOneBtsChart';  ctitle='only one BTS';   ytitle='devices';  nameS='Nbre devices';
                    graphLoad(idChart,ctitle,ytitle,nameS,devOneBts);


                    /*
                   console.log('$scope.histoData',$scope.histoData)
                   console.log('signal Data',signalData)
                   console.log('ack ackDataTrue',ackDataTrue)
                   console.log('dev ackDataFalse',ackDataFalse)

                    console.log('dev devData',devData)
                    console.log('dev devBestRssi',devBestRssi)
                    console.log('dev devOneBts',devOneBts)*/

                }else{

                    var idChart='msgUpChart'; var ctitle='Msgs UpLink';  var ytitle='msgs'; var nameS='Nbre msgs UP';
                    graphLoad(idChart,ctitle,ytitle,nameS,ackDataTrue);

                    idChart='msgDownChart';  ctitle='Msgs DownLink';   ytitle='msgs';  nameS='Nbre msgs Down';
                    graphLoad(idChart,ctitle,ytitle,nameS,ackDataFalse);

                    idChart='devNumberChart';  ctitle='Nbre Devices';   ytitle='devices';  nameS='Nbre devices';
                    graphLoad(idChart,ctitle,ytitle,nameS,devData);

                    idChart='bestRssiChart';  ctitle='Best RSSI Devices';   ytitle='devices';  nameS='Nbre devices';
                    graphLoad(idChart,ctitle,ytitle,nameS,devBestRssi);

                    // idChart='chartSignal';  ctitle='Devices Signal';   ytitle='signal';  nameS='signal';
                    // graphLoad(idChart,ctitle,ytitle,nameS,signalData);

                    idChart='avgRedondancyChart';  ctitle='AVG redondancy';   ytitle='avg';  nameS='redondancy msgs';
                    graphLoad(idChart,ctitle,ytitle,nameS,devRedondancyBts);

                    idChart='devOneBtsChart';  ctitle='only one BTS';   ytitle='devices';  nameS='Nbre devices';
                    graphLoad(idChart,ctitle,ytitle,nameS,devOneBts);

                }

            $scope.searchforData=true;



            }, function (err) {

                if (err && err.status === -1) {
                    Notification.error("Probl�me de connexion Internet");
                }
                console.error("Device message error ", err);
            }, true)

    };

   var graphLoad=function(idChart,title,ytitle,name,data){
console.log('chart call');


       Highcharts.chart(idChart, {
           chart: {
               zoomType: 'x'
           },
           title: {
               text: title
           },

           subtitle: {
               text: $scope.station.id
           },
           yAxis: {
               title: {
                   text:ytitle

               }
           }, xAxis: {
               type: 'datetime',
               dateTimeLabelFormats: { // don't display the dummy year
                   month: '%e. %b',
                   year: '%b'
               },
               title: {
                   text: 'Date'
               }
           },

           legend: {
               layout: 'horizontal',
               align: 'center',
               verticalAlign: 'bottom'
           },

           plotOptions: {
               series: {
                   label: {
                       connectorAllowed: false
                   },
                   pointStart: 2010
               }
           },

           series: [{
               //  name: $translate.instant(scope.item.type),/////////
               name: name,
               data: data
           }],
           responsive: {
               rules: [{
                   condition: {
                       maxWidth: 500
                   },
                   chartOptions: {
                       legend: {
                           layout: 'horizontal',
                           align: 'center',
                           verticalAlign: 'bottom'
                       }
                   }
               }]
           },  credits: {
               enabled: false
           },

       });

}



});