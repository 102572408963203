/**
 * Project: ngiTracking
 * Created by NGI team 09/02/2017.
 */


angular.module("app.directives").directive("searchAddressDirective",  function ($rootScope,
                                                                                $state,
                                                                                dataSrv,
                                                                                $stateParams,
                                                                                leafletData,
                                                                                leafletBoundsHelpers,
                                                                                $q,
                                                                                $sce,$http,geoServiceProxy,$translate) {

    return {
        scope:{
            map:'@'
        },
        templateUrl: 'app/commons/directives/map/search/search.address.drv.tpl.html',

        link: function ($scope) {
            var infolayer = new L.layerGroup();


            $scope.address = {};
            $scope.pois = {};
            var currentPosition = {};

            $scope.close=function(){
                $( "#sidebar-search" ).removeClass( "sidebar-open" );
                $state.go('^')
            };

            $scope.Cityautocomplete_options = {
                suggest: function (term) {
                    if(term.length>=3){
                        var defer = $q.defer();
                        geoServiceProxy.geoCodeAddress(5, 'Tunisie', term, null, null, "fr", "", "", "", "").then(function (res) {
                            result = [];
                            res.elements.forEach(function (value) {
                                result.push({
                                    label: value.addressDetails.city,
                                    "value": value.addressDetails.city,
                                    obj: value
                                });
                            });
                            defer.resolve(result)

                        }, function (error) {
                            // alert(error.errorCode+ " \r\n"+ error.errorString+" \r\n"+JSON.stringify(error.errorDetails));

                        });
                        return defer.promise;
                    }
                },
                on_error: console.log
            };

            $scope.streetAutocomplete_options = {
                suggest: function (term) {
                    if(term.length>=3){

                        var defer = $q.defer();
                        geoServiceProxy.geoCodeAddress(5, 'Tunisie', $scope.address.city, term, null, "xx", "", "", "", "").then(function (res) {
                            result = [];
                            res.elements.forEach(function (value) {
                                result.push({
                                    label: $sce.trustAsHtml(
                                        '<span>' + value.addressDetails.street + ' ' + value.addressDetails.city + '</span>'),
                                    "value": value.addressDetails.street,
                                    obj: value
                                });
                            });
                            defer.resolve(result)

                        }, function (error) {
                            // alert(error.errorCode+ " \r\n"+ error.errorString+" \r\n"+JSON.stringify(error.errorDetails));

                        });
                        return defer.promise;
                    }
                },
                on_error: console.log
            };
            leafletData.getMap("maprealtime").then(function (map) {
                infolayer.addTo(map);
                map.on('popupclose', function(){
                    infolayer.clearLayers();

                })
            });
            leafletData.getMap("maphistoric").then(function (map) {
                infolayer.addTo(map);
                map.on('popupclose', function(){
                    infolayer.clearLayers();

                })
            });
            $scope.geoCodeAdd = function () {
                $scope.elements = [];

                geoServiceProxy.geoCodeAddress(100, 'Tunisie', $scope.address.city, $scope.address.street, $scope.address.streetNumber, "xx", "", "", "", "").then(function (res) {
                    $scope.elements = res.elements;
                    $scope.count = res.count;
                    if($scope.elements.length===1){
                        $scope.onAdressClick($scope.elements[0])
                    }

                }, function (error) {
                    var errormsg = processBNDError(error);
                    Notification.error({title: 'Geo Coding', message: errormsg});
                })

            };
            $scope.searchPois = function () {
                geoServiceProxy.geoCodeAddress(100, 'Tunisie', $scope.pois.city, $scope.pois.name, $scope.pois.name, "xx", "", "", "", "").then(function (res) {
                    $scope.elements = res.elements;
                    $scope.count = res.count;
                }, function (error) {
                    //   alert(error.errorCode+ " \r\n"+ error.errorString+" \r\n"+JSON.stringify(error.errorDetails));
                    var errormsg = processBNDError(error);
                    $rootScope.error = {
                        title: errormsg.errorTitle, message: errormsg.
                            errorDetails
                    };
                    var modalInstance = $modal.open({
                        templateUrl: 'messagebox.html',
                        controller: 'messageBox',
                        size: 'sm',
                        resolve: {
                            items: function () {
                                return $scope.items;
                            }
                        }
                    });
                })
            };

            $scope.onAdressClick = function (adress) {

                leafletData.getMap(""+$scope.map).then(function (map) {
                    if(map.hasLayer(infolayer)){
                        infolayer.clearLayers();

                    }
                    else
                        infolayer.addTo(map);

                    var popLocation = new L.LatLng(adress.lat, adress.lng);
                    currentPosition.latlng = popLocation;
                    var content = '<div class="map-popup"><div class="popup-heading">' + adress.description + '</div><div class="popup-details">' +$translate.instant("LONGITUDE")+ ' : ' + adress.lng + '<br> '+$translate.instant("LATITUDE")+' : ' + adress.lat + '</div>' +
                        '</div>';

                    setTimeout(function () {
                        L.marker([adress.lat,adress.lng]).addTo(infolayer)
                            .bindPopup(content)
                            .openPopup();

                        $scope.$apply();
                    }, 0);


                    map.setView([adress.lat, adress.lng], 18)
                })

            };

            findPoisProximity = function () {
                $scope.changeTab('search');
                geoServiceProxy.findPointOfInterests(100, currentPosition.latlng.lng, currentPosition.latlng.lat, 100, "xx", "", "", "").then(function (res) {
                    var poiList = GetPoiList(res);
                    $scope.elements = poiList;
                    $scope.count = poiList.length;
                }, function (error) {
                    alert(error.errorCode + " \r\n" + error.errorString + " \r\n" + JSON.stringify(error.errorDetails));
                    var errormsg = processBNDError(error);
                    $rootScope.error = {
                        title: errormsg.errorTitle, message: errormsg.
                            errorDetails
                    };
                    var modalInstance = $modal.open({
                        templateUrl: 'messagebox.html',
                        controller: 'messageBox',
                        size: 'sm',
                        resolve: {
                            items: function () {
                                return $scope.items;
                            }
                        }
                    });
                })
            };

            function GetPoiList(res) {
                var poiList = [];

                if (res.BND.FeatureElements.FeatureElement.length > 0) {
                    res.BND.FeatureElements.FeatureElement.forEach(function (value, Index) {
                        if (value.ClassId && value.ClassId > 7000){

                            value.Attributes.forEach(function (elem, Index) {
                            if (elem.Attribute.code === "NAME") {
                                var poi = {};
                                poi.lng = value.Coordinate.x;
                                poi.lat = value.Coordinate.y;
                                poi.code = elem.Attribute.code;
                                poi.value = elem.Attribute.value;
                                poi.description = elem.Attribute.value;
                                poiList.push(poi)
                            }
                        })
                    }
                    })

                }

                return poiList;
            }

            if($state.current.name.startsWith('app.realtime.search')||$state.current.name.startsWith('app.historic.search')){
                setTimeout(function(){
                    $(document).find("aside.search").addClass("opened");
                },100);
            }
        }
    }


});
