/**
 * Project: ngitracking
 * Created by ngiteam 06/02/2017.
 */
var config_dev={
    "APP_NAME": "TC_FLEET",
    "APP_VERSION": "v0.0.2",
    "API_URI": "http://localhost:5001/api/v1.0",
    "LBS_WMS":"https://tcfleet.ngi.tn/bgis/wms?format=png",
    "EVENT_URI":"http://localhost:4999", // socket io
    "BND_URI":"https://tcfleet.ngi.tn/bgis/bnd",
    GOOGLE_CLIENT_ID:"287370020203-rj2onf7i4u598l04j8t5jv6umjp7rib2.apps.googleusercontent.com",
    FACEBOOK_CLIENT_ID:"2005246133089088"

};

var config_prod={
    "APP_NAME": "TC_FLEET",
    "APP_VERSION": "v0.0.2",
    "API_URI": "http://api.iot.tn/api/v1.0",
    "LBS_WMS":"http://new.ngi-fleet.com/ngi-lbs-server/sgg?app=WEENEE_FLEET&cached=true&user=dev-admin&pass=admin_dev&LAYERS=Benomad_TN&FORMAT=image/png&STYLES=day&SERVICE=WMS",
    "EVENT_URI":'http://37.59.29.22:4999/', //socket io
    "BND_URI":"http://localhost:1337/webmap.ngi-maghreb.com/bgis/bnd",
    GOOGLE_CLIENT_ID:"287370020203-rj2onf7i4u598l04j8t5jv6umjp7rib2.apps.googleusercontent.com",
    FACEBOOK_CLIENT_ID:"2005246133089088"
};

var config_dashboard={
    "APP_NAME": "TC_FLEET",
    "APP_VERSION": "v0.0.2",
    "API_URI": "https://dashboard.iot.tn/api/v1.0",
    "LBS_WMS":"https://dashboard.iot.tn/bgis/wms?format=png",
    "EVENT_URI":'wss://dashboard.iot.tn', //socket io
    "BND_URI":"https://dashboard.iot.tn/bgis/bnd",
    GOOGLE_CLIENT_ID:"287370020203-rj2onf7i4u598l04j8t5jv6umjp7rib2.apps.googleusercontent.com",
    FACEBOOK_CLIENT_ID:"2005246133089088"
};


var config_ngigps={
    "APP_NAME": "TC_FLEET",
    "APP_VERSION": "v0.0.2",
    "API_URI": "http://app.ngi-gps.com/api/v1.0",
    "LBS_WMS":"http://www.ngi-gps.com/bgis/wms?format=png",
    "EVENT_URI":'ws://app.ngi-gps.com:4999', //socket io
    "BND_URI":"http://www.ngi-gps.com/bgis/bnd",
    GOOGLE_CLIENT_ID:"287370020203-rj2onf7i4u598l04j8t5jv6umjp7rib2.apps.googleusercontent.com",
    FACEBOOK_CLIENT_ID:"2005246133089088"
};


var config_pre_prod={
    "APP_NAME": "TC_FLEET",
    "APP_VERSION": "v0.0.2",
    "API_URI": "http://10.168.1.100/api/v1.0",
    "LBS_WMS":"http://www.ngi-gps.com/bgis/wms?format=png",
    "EVENT_URI":'ws://10.168.1.100:4999', //socket io
    "BND_URI":"http://www.ngi-gps.com/bgis/bnd",
    GOOGLE_CLIENT_ID:"287370020203-rj2onf7i4u598l04j8t5jv6umjp7rib2.apps.googleusercontent.com",
    FACEBOOK_CLIENT_ID:"2005246133089088"
};

angular.module("app").constant("CONFIG",config_dashboard);

angular.module("app").config([
    '$translateProvider',"$provide", "ngDialogProvider", "NotificationProvider",
    function($translateProvider,$provide,ngDialogProvider, NotificationProvider) {
        $translateProvider.useSanitizeValueStrategy('escapeParameters');
        $translateProvider.useStaticFilesLoader({
            files: [{
                prefix: 'i18n/',
                suffix: '.json'
            }, {
                prefix: 'i18n/server/',
                suffix: '.json'
            }]
        });
        $translateProvider.preferredLanguage('fr');
        $translateProvider.forceAsyncReload(true);

        NotificationProvider.setOptions({
            delay: 10000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'right',
            positionY: 'bottom'
        });

        ngDialogProvider.setDefaults({
            className: 'ngdialog-theme-default'
        });

    }
]);


/*angular.module('app').config([
    'odometerOptionsProvider', function(odometerOptionsProvider) {
        odometerOptionsProvider.defaults = {
            duration: 3000,
            format: 'd',
            theme: 'train-station'
        };
    }
]);*/

/**
 * Authetnication service config Google, fb
 */
angular.module('app').config(["$authProvider","CONFIG","$locationProvider","$stateProvider",function($authProvider,CONFIG,$locationProvider,$stateProvider) {
    $authProvider.httpInterceptor = false;
    $locationProvider.html5Mode(false)

    // Optional: For client-side use (Implicit Grant), set responseType to 'token' (default: 'code')
    const redirectUri = window.location.origin + '/';

    $authProvider.facebook({
        clientId: CONFIG.FACEBOOK_CLIENT_ID,
        responseType: 'token',
        scope: ['public_profile', 'email'],
        redirectUri: redirectUri
    });

    $authProvider.google({
        clientId: CONFIG.GOOGLE_CLIENT_ID,

        responseType: 'token',
        scope: ["profile" , "email"],
        accessType:"offline",
        redirectUri: redirectUri
        //
        }
        )

    /*
     $authProvider.github({
     clientId: 'GitHub Client ID'
     });

     $authProvider.linkedin({
     clientId: 'LinkedIn Client ID'
     });

     $authProvider.instagram({
     clientId: 'Instagram Client ID'
     });

     $authProvider.yahoo({
     clientId: 'Yahoo Client ID / Consumer Key'
     });

     $authProvider.live({
     clientId: 'Microsoft Client ID'
     });*/

    // No additional setup required for Twitter

    /*$authProvider.oauth2({
        name: 'foursquare',
        url: '/auth/foursquare',
        clientId: 'Foursquare Client ID',
        redirectUri: window.location.origin,
        authorizationEndpoint: 'https://foursquare.com/oauth2/authenticate',
    });*/

}]);;
