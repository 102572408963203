
angular.module("app.directives").directive("bndRoadInstruction",  function ($q, geoServiceProxy, $sce) {

    return {
        restrict: "EA",
        scope: {
            roadInstruction: '=',
            htmlContent: '='
        },
        template: '<tr ng-bind-html="htmlContent" ng-hide="htmlContent==\'\'">' +
        '</tr>',
        link: function (scope) {
            scope.$watch('roadInstruction', function (oldVal, newVal) {
                scope.roadInstruction.htmlContent = $sce.trustAsHtml(buildBNDInstruction(newVal));
            })
        }
    }
});
