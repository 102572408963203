angular.module("app.directives").directive("itineraire", function ($rootScope, $state, $translate, dataSrv, $stateParams, $timeout, leafletData, leafletBoundsHelpers, $q, $sce, ngDialog, Notification, geoServiceProxy, $http) {

    return{
        scope:{
            map:'@'
        },
        templateUrl:"app/commons/directives/map/itineraire/itineraire.tpl.html",
        link: function(scope){
            /**
             * Show routing sidebar if current state is "routing"
             */
            if($state.current.name.startsWith('app.realtime.routing')||$state.current.name.startsWith('app.historic.routing')){
                setTimeout(function(){
                    $(document).find("aside.routing").addClass("opened");
                },100);
            }
            scope.currentPosition ={};
            var infolayer = new L.layerGroup();
            scope.steps = [{}, {}];
            scope.criterias = {};
            scope.options = ['ROUTESHEET', 'POLYLINE'];
            scope.criterias.FASTER_SHORTEST = routingConst.criterias.FASTER;
            scope.criterias.AVOID_MOTORWAYS = "";
            scope.criterias.AVOID_TOLLS = "";
            scope.transportType = {};
            scope.transportType.type = 'CAR';
            scope.stepsOpended = true;
            scope.roadSheetOpned = false;
            scope.routelayer = new L.layerGroup();
            var routingIcons = {
                startIcon: L.AwesomeMarkers.icon({
                    icon: '',
                    iconColor: "#fff",
                    markerColor: 'green',
                    prefix: 'fa',
                    html: "A"
                }),

                endIcon: L.AwesomeMarkers.icon({
                    icon: '',
                    markerColor: 'red',
                    prefix: 'fa',
                    html: "B"
                }),

                stepIcon: L.AwesomeMarkers.icon({
                    icon: '',
                    markerColor: 'darkblue',
                    prefix: 'fa',
                    html: ""
                }),

                Instruction: L.icon({

                    'iconUrl': 'app/commons/directives/map/images/fleche_anime.gif',
                    'iconSize': [25, 25], // size of the icon
                    'iconAnchor': [6, 15], // point of the icon which will correspond to marker's location
                    //   'shadowAnchor': [4, 62],  // the same for the shadow
                    'popupAnchor': [-3, -76] // point from which the popup should open relative to the iconAnchor
                })


            };
            scope.selectedObject = {};
            scope.resetRouting = function () {
                scope.paths = {};
                scope.steps = [{}, {}];
                scope.routing = {};
                scope.routeInstruction = {};
                scope.stepsOpended = false;
                scope.roadSheetOpned = true;

                scope.routelayer.clearLayers();
            };
            $rootScope.$on('routingExit', function () {
                scope.resetRouting();
            });
            scope.address = {};
            scope.pois = {};
            scope.Cityautocomplete_options = {
                suggest: function (term) {
                    if(term.length>=3){

                        var defer = $q.defer();
                        var req = {
                            query: {NOM_FR: {$regex: '^' + scope.address.city, $options: "i"}}
                        };

                        scope.selectedCITY = {SVS_NAME: term};
                        $http.post($rootScope.config.API_URI + '/svs_city/query?limit=10', req).success(function (data) {
                            // this callback will be called asynchronously
                            // when the response is available
                            result = [];
                            if (data && data.success) {
                                data.result.forEach(function (value) {
                                    result.push({
                                        label: $sce.trustAsHtml(
                                            '<span>' + value.NOM_FR + '</span>'),
                                        "value": value.NOM_FR,
                                        obj: value
                                    });
                                });
                                defer.resolve(result)

                            }

                        }).error(function (data) {
                            console.log(data)
                        });

                        return defer.promise;
                    }
                },
                on_select: function (selected) {
                    scope.selectedCITY = selected.obj;
                },
                on_error: console.log
            };
            scope.streetAutocomplete_options = {

                suggest: function (term) {
                    if(term.length>=3){

                        var defer = $q.defer();
                        var req = {
                            query: {NOM_FR: {$regex: scope.address.street, $options: "i"}}
                        };
                        $http.post($rootScope.config.API_URI + '/svs_address/query?limit=10', req).success(function (data) {
                            // this callback will be called asynchronously
                            // when the response is available
                            result = [];
                            if (data && data.success) {
                                data.result.forEach(function (value) {
                                    result.push({
                                        label: $sce.trustAsHtml(
                                            '<span>' + value.NOM_FR + '</span>'),
                                        "value": value.NOM_FR,
                                        obj: value
                                    });
                                });
                                defer.resolve(result)

                            }
                            else {


                            }


                        }).error(function (data) {
                            console.log(data)

                        });

                        return defer.promise;
                    }
                },
                on_error: console.log,
                on_select: function (selected_item) {
                    //console.log(selected_item);

                    // scope.updateShowDetailed(true);


                }
            };
            scope.geoCodeAdd = function () {
                if (!scope.address.street) {
                    var req = {
                        query: {NOM_FR: {$regex: '^' + scope.address.city, $options: "i"}}
                    };
                    $http.post($rootScope.config.API_URI + '/svs_city/query?limit=10', req).success(function (data) {
                        // this callback will be called asynchronously
                        // when the response is available
                        scope.elements = [];
                        if (data && data.success) {
                            data.result.forEach(function (value) {
                                var el = {};
                                el.description = value.NOM_FR;
                                el.lng = value.loc.coordinates[0];
                                el.lat = value.loc.coordinates[1];
                                scope.elements.push(el)
                            });

                            scope.count = scope.elements.length;
                        }
                        else {


                        }
                        if(scope.elements.length===1){
                            scope.onAdressClick(scope.elements[0])
                        }



                    }).error(function (data) {
                        console.log(data)

                    });

                } else {
                    geoServiceProxy.geoCodeAddress(100, 'Tunisie', scope.selectedCITY.SVS_NAME, scope.address.street, scope.address.streetNumber, "xx", "", "", "", "").then(function (res) {
                        scope.elements = res.elements;
                        scope.count = res.count;
                        if(scope.elements.length===1){
                            scope.onAdressClick(scope.elements[0])
                        }

                    }, function (error) {
                        var errormsg = processBNDError(error);
                        Notification.error({title: 'Geo Coding', message: errormsg});

                    })
                }


            };
            scope.searchPois = function () {
                geoServiceProxy.geoCodeAddress(100, 'Tunisie', scope.pois.city, scope.pois.name, scope.pois.name, "xx", "", "", "", "").then(function (res) {
                    scope.elements = res.elements;
                    scope.count = res.count;
                }, function (error) {
                    //   alert(error.errorCode+ " \r\n"+ error.errorString+" \r\n"+JSON.stringify(error.errorDetails));
                    var errormsg = processBNDError(error);
                    Notification.error({title: 'Search Pois', message: errormsg});
                })

            };
            /**
             * Fastest/Shortest Toggle Button
             *
             * @type {JQuery|jQuery|HTMLElement}
             */
            var leftRadio = $("label.left input[type=checkbox]");
            var rightRadio = $("label.right input[type=checkbox]");
            var center = $(".toggle span.center");
            if(scope.criterias.FASTER_SHORTEST === 'FASTER'){
                center.css({"left": "0"});
                $('label.left').css({"background-color": "#5cb85c"});
                $('label.right').css({
                    "background-color":"rgba(0,0,0,.1)"
                })
            }else{
                center.css({
                    "right": "0",
                    "left": "auto",
                    "transition-timing-function": "ease-out",
                    "transition": "0.5s"
                });
                $('label.right').css({"background-color": "#5cb85c"});
                $('label.left').css({
                    "background-color":"rgba(0,0,0,.1)"
                })
            }
            leftRadio.on("click", function(){
                center.css({
                    "left": "0",
                    "transition-timing-function": "ease-out",
                    "transition": "0.5s"
                });
                $('label.left').css({"background-color": "#5cb85c"});
                $('label.right').css({
                    "background-color":"rgba(0,0,0,.1)"
                })
            });
            rightRadio.on("click", function(){
                center.css({"right": "0", "left": "auto"});
                $('label.right').css({"background-color": "#5cb85c"});
                $('label.left').css({
                    "background-color":"rgba(0,0,0,.1)"
                })
            });
            scope.onAdressClick = function (adress) {
                var popLocation = new L.LatLng(adress.lat, adress.lng);
                currentPosition.latlng = popLocation;
                var content = '<div class="map-popup"><div class="popup-heading">' + adress.description + '</div><div class="popup-details">' + $translate.instant("LONGITUDE") + adress.lng + '<br> '+$translate.instant("LATITUDE")+' : ' + adress.lat + '</div>' +
                    '<div align="right"><img src="app/commons/directives/map/images/poi.png" style="cursor: pointer;padding-right: 1em;" onClick="getProximityPoi()" title="'+$translate.instant("NEARBY_POINT")+'"><img src="app/commons/directives/map/images/start_flag_16.png" style="cursor: pointer;padding-right: 1em;" onClick="getStartPoint()" title="'+$translate.instant("STARTING_POINT")+'">' +
                    '<img src="app/commons/directives/map/images/finish_flag_16.png" style="cursor: pointer;" onClick="getEndPoint()" title="'+$translate.instant("ARRIVAL_POINT")+'"></div></div>';



                leafletData.getMap(""+scope.map).then(function (map) {
                    scope.routelayer.clearLayers();
                    setTimeout(function () {
                        map.setView([adress.lat, adress.lng], 16);
                        L.marker([adress.lat, adress.lng]).addTo(scope.routelayer)
                            .bindPopup(content)
                            .openPopup();

                        scope.$apply();
                    }, 0);
                });





            };
            scope.addNewStep = function () {
                scope.steps.splice(scope.steps.length - 1, 0, {});
            };
            scope.removeStep = function (index) {
                scope.steps.splice(index, 1);
            };
            scope.checkOptimizedTrip = function () {
                if(scope.options.indexOf('OPTIMIZED_TRIP') !== -1){
                    scope.options.splice(scope.options.indexOf('OPTIMIZED_TRIP'), 1);
                }else{
                    scope.options.push('OPTIMIZED_TRIP');
                }
             //   console.log(scope.options , " isoptimized : ", scope.isOptimizedTrip())
            };
            scope.isOptimizedTrip = function () {
                return scope.options.indexOf('OPTIMIZED_TRIP') !== -1;
            };
            scope.calculateRoute = function () {
                leafletData.getMap(""+scope.map).then(function (map) {
                    scope.paths=map.getContainer().attributes.paths;

                    var criterias = [scope.criterias.FASTER_SHORTEST];
                    if (scope.criterias.AVOID_MOTORWAYS === routingConst.criterias.AVOID_MOTORWAYS)
                        criterias.push(routingConst.criterias.AVOID_MOTORWAYS);
                    if (scope.criterias.AVOID_TOLLS === routingConst.criterias.AVOID_TOLLS)
                        criterias.push(routingConst.criterias.AVOID_TOLLS);
                   // console.log(criterias)

                    geoServiceProxy.calculateRoute(scope.steps, criterias, scope.options,scope.transportType.type, 'fr').then(function (res) {
                            if (res.BND && res.BND.Routes) {
                                var polyline = res.BND.Routes.Route[0].Polyline.Line;
                                var usedDestination = res.BND.UsedDestinations.UsedDestination;
                                scope.usedDestination = res.BND.UsedDestinations.UsedDestination;
                                var rt = {
                                    color: '#74EC2E',
                                    weight: 3,
                                    latlngs: []

                                };

                                polyline.forEach(function (value) {
                                    rt.latlngs.push({lng: value.X, lat: value.Y});
                                });

                                scope.paths = {route1: rt};

                                zoomOverPolyline();

                                usedDestination.forEach(function () {
                                    var usedDest = {
                                        color: 'black',
                                        weight: 2,
                                        latlngs: []
                                    }
                                });
                                scope.durationRoute={};
                                scope.routing = {};
                                scope.stepsOpended = false;
                                scope.roadSheetOpned = true;
                                scope.routeInstruction = res.BND.Routes.Route[0];
                                scope.durationRoute =   moment("1900-01-01 00:00:00").add(scope.routeInstruction.Duration.value, 'seconds').format("HH:mm:ss");

                                updateMarkers(scope.steps);

                            }

                        },

                        function (error) {
                            scope.routeInstruction = {};
                            scope.paths = {};
                            scope.stepsOpended = false;
                            scope.roadSheetOpned = true;
                            var processedError = processBNDError(error);
                            scope.routing = {error: processedError.errorDetails};

                            //  alert(processedError.errorDetails);
                        });


                })
            };
            leafletData.getMap(""+scope.map).then(function (map) {
                //infolayer.clearLayers();
                scope.paths = map.getContainer().attributes.paths;
                scope.routelayer.addTo(map);
            });
            function updateMarkers(steps) {
                var routingMarkers = [];
                //infolayer.clearLayers();
                scope.routelayer.clearLayers();
                steps.forEach(function (value, index) {
                    if (value.lng !== null && value.lng !== undefined) {
                        if (index === 0) {
                            routingMarkers.push(L.marker([value.lat,value.lng],{icon:routingIcons.startIcon}))

                        } else if (index === scope.steps.length - 1) {
                            routingMarkers.push(L.marker([value.lat,value.lng],{icon:routingIcons.endIcon}))
                        } else {

                            if(angular.isDefined(scope.usedDestination)){
                                routingMarkers.push(L.marker([value.lat,value.lng], {icon: L.AwesomeMarkers.icon({icon: '', markerColor: 'darkblue', prefix: 'fa', html: scope.usedDestination[index].usedOrder}) }))
                            }else{
                                routingMarkers.push(L.marker([value.lat,value.lng], {icon: L.AwesomeMarkers.icon({icon: '', markerColor: 'darkblue', prefix: 'fa', html:index}) }))
                            }

                        }
                    }

                    if (index === steps.length - 1) {
                        routingMarkers.forEach(function (value) {
                            value.addTo(scope.routelayer)

                        })
                    }
                })
            }
            function zoomOverPolyline() {
                var polyline = L.polyline(scope.paths.route1.latlngs, {color: '#0044FA'});
                var bounds = polyline.getBounds();

                leafletData.getMap(""+scope.map).then(function (map) {
                    polyline.addTo(scope.routelayer);

                    map.fitBounds(bounds, {paddingTopLeft: [400, 60]});

                })


            }
            function GetPoiList(res) {
                var poiList = [];

                if (res.BND.FeatureElements.FeatureElement.length > 0) {
                    res.BND.FeatureElements.FeatureElement.forEach(function (value) {
                        if (value.ClassId && value.ClassId > 7000){

                            value.Attributes.forEach(function (elem) {
                            if (elem.Attribute.code === "NAME") {
                                var poi = {};
                                poi.lng = value.Coordinate.x;
                                poi.lat = value.Coordinate.y;
                                poi.code = elem.Attribute.code;
                                poi.value = elem.Attribute.value;
                                poi.description = elem.Attribute.value;
                                poiList.push(poi)
                            }
                        })
                    }
                    })

                }

                return poiList;
            }
            getStartPoint = function () {
                addStartStep(currentPosition)

            };
            getEndPoint = function () {
                addFinishStep(currentPosition)

            };
            function addStartStep(e) {
                geoServiceProxy.reverseGeoCode(1, e.latlng.lng, e.latlng.lat, 10000, "fr").then(function (res) {
                    if (res.count > 0) {
                        startPoint = res.elements[0];
                        startPoint.lng = e.latlng.lng;
                        startPoint.lat = e.latlng.lat;
                        scope.steps[0] = startPoint

                    } else {
                        var address = {
                            country: "",
                            city: "",
                            postalCode: "",
                            street: $translate.instant("UNKNOWN_ADDRESS"),
                            district: "",
                            countryCode: "",
                            streetNumber: ""
                        };
                        var startPoint = new ngiWebMap.Point(e.latlng.lng, e.latlng.lat, $translate.instant("UNKNOWN_ADDRESS"), "", null, address);
                        startPoint.lng = e.latlng.lng;
                        startPoint.lat = e.latlng.lat;
                        scope.steps[0] = startPoint;
                        //console.log(scope.steps);

                    }

                    updateMarkers(scope.steps);
                    if (scope.steps[0].lng !== null && scope.steps[0].lng !== undefined && scope.steps[scope.steps.length - 1].lng !== null && scope.steps[scope.steps.length - 1].lng !== undefined) {
                        scope.calculateRoute();
                    }
                }, function (error) {
                    //  alert(error);

                })

            }
            function addFinishStep(e) {

                geoServiceProxy.reverseGeoCode(1, e.latlng.lng, e.latlng.lat, 1000, "fr").then(function (res) {
                    if (res.count > 0) {
                        var endPoint = res.elements[0];
                        endPoint.lng = e.latlng.lng;
                        endPoint.lat = e.latlng.lat;
                        scope.steps[scope.steps.length - 1] = endPoint;
                        //console.log(scope.steps);
                    } else {
                        var address = {
                            country: "",
                            city: "",
                            postalCode: "",
                            street: $translate.instant("UNKNOWN_ADDRESS"),
                            district: "",
                            countryCode: "",
                            streetNumber: ""
                        };
                        endPoint = new ngiWebMap.Point(e.latlng.lng, e.latlng.lat, $translate.instant("UNKNOWN_ADDRESS"), "", null, address);
                        endPoint.lng = e.latlng.lng;
                        endPoint.lat = e.latlng.lat;
                        scope.steps[scope.steps.length - 1] = endPoint
                    }
                    updateMarkers(scope.steps);
                    if (scope.steps[0].lng !== null && scope.steps[0].lng !== undefined && scope.steps[scope.steps.length - 1].lng !== null && scope.steps[scope.steps.length - 1].lng !== undefined) {
                        scope.calculateRoute();
                    }


                }, function (error) {
                    console.log(error);
                    alert(error);

                })

            }
            function addStep(e) {

                geoServiceProxy.reverseGeoCode(1, e.latlng.lng, e.latlng.lat, 1000, "fr").then(function (res) {
                    if (res.count > 0) {
                        var stepPoint = res.elements[0];
                        stepPoint.lng = e.latlng.lng;
                        stepPoint.lat = e.latlng.lat;

                        scope.steps.splice(scope.steps.length - 1, 0, stepPoint);
                        scope.steps.join();
                    } else {
                        var address = {
                            country: "",
                            city: "",
                            postalCode: "",
                            street: $translate.instant("UNKNOWN_ADDRESS"),
                            district: "",
                            countryCode: "",
                            streetNumber: ""
                        };
                        var stepPoint = new ngiWebMap.Point(e.latlng.lng, e.latlng.lat, $translate.instant("UNKNOWN_ADDRESS"), "", null, address);
                        stepPoint.lng = e.latlng.lng;
                        stepPoint.lat = e.latlng.lat;

                        scope.steps.splice(scope.steps.length - 1, 0, stepPoint);
                        scope.steps.join();
                    }

                    updateMarkers(scope.steps);
                    if (scope.steps[0].lng !== null && scope.steps[0].lng !== undefined && scope.steps[scope.steps.length - 1].lng !== null && scope.steps[scope.steps.length - 1].lng !== undefined) {
                        scope.calculateRoute();
                    }

                }, function (error) {
                    //console.log(error);
                    alert(error);

                })
            }
/*            /!* Get address*!/
            function infos(e) {
                infolayer.clearLayers();
                var popLocation = new L.LatLng(e.latlng.lat, e.latlng.lng);
                scope.currentPosition.latlng = popLocation;
                geoServiceProxy.findPointOfInterests(1, e.latlng.lng, e.latlng.lat, 100, "xx", "", "", "").then(function (res) {
                    if (res.BND.GeocodingElements.GeocodingElement.length > 0) {
                        var postalAddress = res.BND.GeocodingElements.GeocodingElement[0].PostalAddress;
                        var message = "";
                        var address="";
                        if (postalAddress.Street)
                            message = postalAddress.Street;


                        if (postalAddress.District) {
                            if (message)
                                message += ', ' + postalAddress.District + ', ' + postalAddress.City;
                            else
                                message = postalAddress.District + ', ' + postalAddress.City
                        }
                        if(message)
                            address='<i class="fa fa-globe"></i> '+message;
                        if (res.BND.FeatureElements.FeatureElement.length > 0) {
                            res.BND.FeatureElements.FeatureElement.forEach(function (value) {
                                value.Attributes.forEach(function (elem) {
                                    if(elem.Attribute.code==="NAME" && elem.Attribute.value!==""){
                                        if(address)
                                            address += '<br>';
                                        address += '<i class="fa fa-map-marker"></i> ' +elem.Attribute.value;
                                    }
                                })
                            })

                        }
                        if(!address)
                            address = '<i class="fa fa-globe"></i> ' +$translate.instant("UNDEFINED_ADDRESS");
                        message = '<div class="map-popup"><div class="popup-heading">' + address + '</div><div class="popup-details">' + 'Longitude : ' + e.latlng.lng + '<br> Latitude : ' + e.latlng.lat + '</div>' +
                            '<div align="right"><img src="./app/commons/directives/map/images/start_flag_16.png" style="cursor: pointer;padding-right: 1em;" onClick="startStep()" title="$translate.instant("STARTING_POINT")">' +
                            '<img src="./app/commons/directives/map/images/finish_flag_16.png" style="cursor: pointer;" onClick="finishStep()" title="$translate.instant("ARRIVAL_POINT")"></div></div>';
                        setTimeout(function () {
                            L.marker([e.latlng.lat,e.latlng.lng]).addTo(infolayer)
                                .bindPopup(message)
                                .openPopup();

                            scope.$apply();
                        }, 0);

                    }
                    else {

                    }
                }, function (error) {
                })

            }*/
            scope.$on('poi:zoom', function (event, args) {
                leafletData.getMap(""+scope.map).then(function (map) {

                    map.setView([args.lat, args.lng], 18)
                })
            });
            scope.$on('addStartStep', function (event, e) {

                addStartStep(e);
            });
            scope.$on('addStep', function (event, e) {
                addStep(e);
            });
            scope.$on('addFinishStep', function (event, e) {
                addFinishStep(e);
            });



        }
    }
});